import { SCTN_TYPE_NAV, getFormattedMessage, getSuggestionNavItemInfo } from './utils';
import cssModules from '../styles/modules.css';
import { SuggestionItem } from './SuggestionItem';

/**
 * class to render the nav suggestion item
 * @class NavSuggestionItem
 * @extends SuggestionItem
 */
class NavSuggestionItem extends SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {Number} options.index item index for this item
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     */
    constructor(options) {
        super(options);
        this.type = SCTN_TYPE_NAV;
        this.testId = 'srch-nav';
        const { text } = getSuggestionNavItemInfo(this.suggestion);
        this.title = text;
    }

    /**
     * Helper function to render the nav suggestion item
     * @method render
     * @memberof NavSuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        const linkItem = super.render();
        const contentNode = document.createElement('div');
        contentNode.className = cssModules.navItemContent;
        contentNode.innerHTML = getFormattedMessage('GOTO_TEXT', {
            text: `<strong>${this.title}</strong>`
        });
        linkItem.appendChild(contentNode);

        return linkItem;
    }
}

export default NavSuggestionItem;
