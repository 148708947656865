import { SCTN_TYPE_RECOMMEND } from './utils';
import TrendingIcon from '@vzmi/svg-icons/icons/trending';
import Icon from './Icon';
import cssModules from '../styles/modules.css';
import { SuggestionItem } from './SuggestionItem';

/**
 * class to render the recommended suggestion item
 * @class RecommendSuggestionItem
 * @extends SuggestionItem
 */
class RecommendSuggestionItem extends SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {Number} options.index item index for this item
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     */
    constructor(options) {
        super(options);
        this.type = SCTN_TYPE_RECOMMEND;
        this.testId = 'srch-trd-sym';
        this.title = this.suggestion.symbol;
    }

    /**
     * Helper function to render the nav suggestion item
     * @method render
     * @memberof RecommendSuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        const { symbol } = this.suggestion;
        const linkItem = super.render();

        const leftColumn = document.createElement('div');
        leftColumn.className = cssModules.recLeftCol;
        const rightColumn = document.createElement('div');
        rightColumn.className = cssModules.recRightCol;
        rightColumn.textContent = symbol;

        leftColumn.appendChild(
            new Icon({
                icon: TrendingIcon,
                className: cssModules.recIcon
            }).render()
        );

        linkItem.appendChild(leftColumn);
        linkItem.appendChild(rightColumn);

        return linkItem;
    }
}

export default RecommendSuggestionItem;
