import QuoteSuggestionItem from './QuoteSuggestionItem';
import NewsSuggestionItem from './NewsSuggestionItem';
import ListSuggestionItem from './ListSuggestionItem';
import NavSuggestionItem from './NavSuggestionItem';
import RecommendSuggestionItem from './RecommendSuggestionItem';
import ResearchReportsSuggestionItem from './ResearchReportsSuggestionItem';
import Icon from './Icon';
import AttentionIcon from '@vzmi/svg-icons/icons/attention';
import CloseIcon from '@vzmi/svg-icons/icons/close';
import DeclineIcon from '@vzmi/svg-icons/icons/decline-fill';

import {
    FIN_SRCH_CONTEXT,
    RAPID_SEC,
    RAPID_SUBSEC,
    SCTN_TYPE_LIST,
    SCTN_TYPE_NAV,
    SCTN_TYPE_NEWS,
    SCTN_TYPE_QUOTES,
    SCTN_TYPE_RECOMMEND,
    SCTN_TYPE_RESEARCH_REPORTS,
    SRCH_SECTION_CONF,
    SRCH_TYPES,
    SRCH_TYPE_CONFIG,
    SRCH_URL_CONF,
    beaconClick,
    generateURL,
    getBase64Encoding,
    getClosestAncestor,
    getFormattedMessage,
    getNewUpdatedIndexObj,
    getSuggestionNavItemInfo
} from './utils';
import cssModules from '../styles/modules.css';

// Constants
const CLASS_CLEAR_BTN = 'finsrch-clear-btn';
const ATTR_CLEAR_BTN = 'srchClearBtn';
const CLASS_SHOW_FOOTER = 'finsrch-show-ftr';
const ATTR_SHOW_FOOTER = 'srchShowFtr';
const CLASS_CUSTOM_PANEL = 'finsrch-custom-rst';
const ATTR_CUSTOM_PANEL = 'srchCustomResult';
const DEFAULT_TOP_STYLE_RESULTS = 32;
const SUGGESTION_LIST_ID = 'search-assist-input-sugglst';
const RECOMMENDATION_LIST_ID = 'search-assist-input-recomlst';
const SCREENER_LINK_ID = 'fin-screener-ln';
const FOOTER_ID = 'search-assist-footer';
const DEFAULT_TAR = 'finance.yahoo.com';
const SEARCH_HOST_MAP = {
    e1: 'espanol.search.yahoo.com',
    cf: 'qc.search.yahoo.com',
    us: 'search.yahoo.com'
};

/**
 * A class instance for displaying the search results
 * @class FinSuggestionList
 */
class FinSuggestionList {
    /**
     * @constructor
     * @memberof FinSuggestionList
     * @param {Object} options options for the constructor
     * @param {Node} options.container results container DOM node
     * @param {Node} options.formNode the topmost form node
     * @param {Node} options.input the input element node
     * @param {Boolean} [options.isFullWindowExperience] if true, then enables full window experience.
     * @param {Boolean} [options.enableModernTheme] if true, then enables modern look and feel.
     * @param {Boolean} [options.enableRecommendation] if true, then enables recommendation list.
     * @param {String} [options.type=all] type of the search functionality
     * @param {Function} [options.onSelectCb] callback after when user has selected an item from the list
     * @param {Function} [options.onClearCb] callback for clearing the input
     */
    constructor({
        container,
        formNode,
        input,
        isFullWindowExperience,
        enableModernTheme,
        enableRecommendation,
        type = SRCH_TYPES.ALL,
        onSelectCb,
        onClearCb
    }) {
        this.container = container;
        this.formNode = formNode;
        this.onSelectCb = onSelectCb;
        this.onClearCb = onClearCb;
        this.input = input;
        this.isFullWindowExperience = isFullWindowExperience;
        this.query = '';
        this.type = type;
        this.suggestions = null;
        this.recommendations = null;
        this.clearBtn = null;
        (this.enableModernTheme = enableModernTheme), (this.selectedIndex = 0);
        this.enableRecommendation = enableRecommendation;

        this.onSelect = this.onSelect.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onScreenerClick = this.onScreenerClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.loadSuggestions = this.loadSuggestions.bind(this);
        this.hideSuggestions = this.hideSuggestions.bind(this);
        this.showSuggestions = this.showSuggestions.bind(this);

        this.init(); // initialize
    }

    /**
     * Initializes this instance
     * @method init
     * @memberof FinSuggestionList
     * @private
     */
    init() {
        if (this.container) {
            this.container.classList.add(cssModules.noDisplay); // initially hide the container
            const useCustomStyle =
                this.container.getAttribute(ATTR_CUSTOM_PANEL) ||
                this.container.classList.contains(CLASS_CUSTOM_PANEL);
            const resultsNode = document.createElement('div');
            if (!useCustomStyle) {
                resultsNode.className = cssModules.suggestionList;
                resultsNode.style.top = `${
                    this.input ? this.input.offsetHeight : DEFAULT_TOP_STYLE_RESULTS
                }px`; // Add appropriate top style
            }
            const suggestionListNode = document.createElement('div');
            suggestionListNode.className = this.isFullWindowExperience
                ? cssModules.resultsContainerShort
                : cssModules.resultsContainer;
            suggestionListNode.setAttribute('data-id', SUGGESTION_LIST_ID);
            suggestionListNode.addEventListener('click', this.onSelect);
            suggestionListNode.addEventListener('keydown', this.onKeyDown);
            resultsNode.appendChild(suggestionListNode);
            this.container.appendChild(resultsNode);

            // create a footer content with search tip
            const footerNode = document.createElement('footer');
            footerNode.setAttribute('data-id', FOOTER_ID);
            footerNode.className = cssModules.noDisplay;
            this.container.appendChild(footerNode);
            const tipNode = document.createElement('p');
            tipNode.className = cssModules.tipMessage;
            tipNode.textContent = getFormattedMessage('SEARCH_TIP');
            footerNode.appendChild(tipNode);
            resultsNode.appendChild(footerNode);

            // If the type of search is `all` or `researchReports` and there is some input already.
            if (this.input) {
                // Add clear button
                this.clearBtn =
                    this.formNode.querySelector(`.${CLASS_CLEAR_BTN}`) ||
                    this.formNode.querySelector(`[${ATTR_CLEAR_BTN}]`);
                if (!this.clearBtn) {
                    // Create clear button
                    this.clearBtn = document.createElement('button');
                    this.clearBtn.className = `${cssModules.clearBtn}`;
                    const icon = new Icon({
                        icon: this.type === SRCH_TYPES.QUOTE ? DeclineIcon : CloseIcon
                    }).render();
                    icon.setAttribute('class', cssModules.cancelIcon);
                    this.clearBtn.appendChild(icon);
                    // Add this button to the form.
                    if (this.input?.nextSibling) {
                        // we will insert clear button after the input with relative positioning, and get it on top of input
                        this.input.parentElement.insertBefore(
                            this.clearBtn,
                            this.input.nextSibling
                        );
                        this.input.style.display = 'inline-block';
                    } else {
                        // This seems like only input is the child, hence just append
                        this.input.parentElement.appendChild(this.clearBtn);
                    }
                }
                this.clearBtn.setAttribute('type', 'button');
                this.clearBtn.setAttribute('title', getFormattedMessage('CLEAR'));
                this.clearBtn.classList.add(cssModules.noDisplay);
                this.clearBtn.addEventListener('click', this.onClear);
            }
        }
    }
    /**
     * Loads new suggestion data for Suggestion list
     * @method loadSuggestions
     * @memberof FinSuggestionList
     * @param {String} query query for the suggestions
     * @param {Object} suggestions suggestions from the query returned by the autocomplete request
     * @public
     */
    loadSuggestions(query, suggestions) {
        // first check if the suggestion is an old and invalid suggestion.
        if (
            this.suggestions &&
            suggestions &&
            this.suggestions.timestamp > suggestions.timestamp
        ) {
            // This is an old suggestion. hence ignore this suggestion.
            return;
        }

        // Valid suggestion
        this.clear();
        this.query = query;
        this.suggestions = suggestions;
        this.suggestions.query = query;

        // create the list
        this.render();
        // update selected index
        this.updateSelectedIndex('reset');
    }

    loadRecommendations(recommendations) {
        if (recommendations) {
            this.recommendations = recommendations;
        }
        // Render the recommendations
        this.render();
        // update selected index
        this.updateSelectedIndex('reset');
    }

    /**
     * Handler for when item is selected from the suggestion list
     * @method onSelect
     * @memberof FinSuggestionList
     * @param {Object} e event object
     * @private
     */
    onSelect(e) {
        // Fire rapid click
        const node = getClosestAncestor(e.target, '[role="option"]', this.container);
        if (!node) {
            return;
        }
        const type = node.getAttribute('data-type');
        const index = Number(node.getAttribute('data-index'));
        const positionIndex = Number(node.getAttribute('data-pindex'));
        this.handleItemSelect(e, { type, index, positionIndex }, (obj) => {
            if (typeof this.onSelectCb === 'function') {
                // if there is a callback
                this.onSelectCb(obj);
            }
        });
    }

    /**
     * Handler for when clear button is clicked
     * @method onClear
     * @memberof FinSuggestionList
     * @param {Object} e event object
     * @private
     */
    onClear(e) {
        // fire rapid click
        beaconClick(RAPID_SEC, 'clr-click', {
            subsec: RAPID_SUBSEC,
            itc: 1,
            elm: 'icn',
            elmt: 'clr',
            outcm: 'srch-clr'
        });

        this.clearBtn.classList.add(cssModules.noDisplay); // hide button
        this.input.value = ''; // clear input
        if (typeof this.onClearCb === 'function') {
            // call clear callback
            this.onClearCb(e);
        }
        // After the clear button click, focus on the input
        this.input.focus();
    }

    /**
     * Handler for when screener link is clicked
     * @method onScreenerClick
     * @memberof FinSuggestionList
     * @param {Object} e event object
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    onScreenerClick() {
        // Fire rapid click
        const targetUrl = generateURL('screener');
        beaconClick(
            RAPID_SEC,
            'scrnr-ln',
            {
                subsec: RAPID_SUBSEC,
                itc: 0,
                elm: 'itm',
                elmt: 'link',
                rspns: 'nav',
                ct: 'screener',
                tar: window.location.hostname || DEFAULT_TAR,
                // eslint-disable-next-line camelcase
                tar_uri: targetUrl
            },
            () => {
                // navigate to the screener
                window.location.assign(targetUrl);
            }
        );
    }
    /**
     * Handler for when key is pressed in suggestion list
     * @method onKeyDown
     * @memberof FinSuggestionList
     * @param {Object} e event object
     * @private
     */
    onKeyDown(e) {
        if (e.key === 'Enter') {
            // when clicked on Enter
            this.onSelect(e);
        }
    }
    /**
     * Handler for when key is pressed in the document anywhere
     * @method onDocumentClick
     * @memberof FinSuggestionList
     * @param {Object} e event object
     * @private
     */
    onDocumentClick(e) {
        if (this.formNode.contains(e.target)) {
            return;
        }
        this.hideSuggestions();
    }
    /**
     * Handler for when submit happens in the search
     * @method onSubmit
     * @memberof FinSuggestionList
     * @param {Object} e event object
     */
    onSubmit(e) {
        const isRecommendation = !this.suggestions && this.query === '';
        const itemList = this.container.querySelectorAll(
            `[data-id="${isRecommendation ? RECOMMENDATION_LIST_ID : SUGGESTION_LIST_ID}"] li`
        );

        if (itemList.length > 0) {
            // There is are items, and something was selected.
            const selectedItem = itemList[this.selectedIndex];
            const type = selectedItem.getAttribute('data-type');
            const index = Number(selectedItem.getAttribute('data-index'));
            const positionIndex = Number(selectedItem.getAttribute('data-pindex'));
            this.handleItemSelect(
                e,
                { type, index, positionIndex, i13nModel: { elm: 'kybrd' } },
                (obj) => {
                    if (typeof this.onSelectCb === 'function') {
                        // if there is a callback
                        this.onSelectCb(obj);
                    }
                }
            );
        } else if (this.type === SRCH_TYPES.ALL || this.type === SRCH_TYPES.RESEARCH_REPORTS) {
            // This means there were no suggestions for this query. It must be a web search or an api failure
            const noResults = !!this.suggestions && this.suggestions.count === 0;

            beaconClick(
                RAPID_SEC,
                'srch-enter',
                {
                    subsec: RAPID_SUBSEC,
                    itc: 0,
                    elm: 'kybrd',
                    rspns: 'nav',
                    ct: noResults ? 'web-search' : 'others',
                    pp: { query: this.query },
                    tar: window.location.hostname || DEFAULT_TAR
                },
                () => {
                    // TODO: We need to do a search retry if the api failed for some reason.
                    const intl = (window[FIN_SRCH_CONTEXT] || {}).intl || 'en';
                    const searchHostName = SEARCH_HOST_MAP[intl] || SEARCH_HOST_MAP.us;

                    // Goto to search
                    window.location.assign(
                        `https://${searchHostName}/search?p=${this.query}&fr=uh3_finance_vert&fr2=p:finvsrp,m:sb`
                    );
                }
            );
        }
    }
    /**
     * Handles an item select
     * @method handleItemSelect
     * @memberof FinSuggestionList
     * @param {Object} e event object
     * @param {Object} options options for the handler
     * @param {Function} [options.callback] callback for the item select
     * @param {String} options.type type of the suggestion
     * @param {Number} options.index index of the suggestion
     * @param {Number} options.positionIndex index of suggestion in the overall list across all sections
     * @param {Object} options.i13nModel i13nModel for rapid click
     * @param {Function} [callback] callback for the item select
     * @private
     */
    handleItemSelect(e, { type, index, positionIndex, i13nModel }, callback) {
        let selectedSuggestion =
            (this.query ? this.suggestions?.[type]?.[index] : this.recommendations?.[index]) ||
            {};
        switch (type) {
            case SCTN_TYPE_QUOTES:
                {
                    // Quote item was selected
                    let cat = (selectedSuggestion.quoteType || '').toLowerCase();
                    let targetUrl = generateURL('quote', {
                        symbol: selectedSuggestion.symbol
                    });
                    if (selectedSuggestion.isYahooFinance === false) {
                        // This is a private company.
                        const { permalink } = selectedSuggestion;
                        selectedSuggestion = {
                            isYahooFinance: false,
                            symbol: permalink ? permalink.toUpperCase() : '-',
                            longname: selectedSuggestion.name || ''
                        };
                        cat = 'private';
                        const hashStr = getBase64Encoding(
                            JSON.stringify({
                                e: permalink,
                                n: selectedSuggestion.longname || ''
                            })
                        );
                        targetUrl = generateURL('company', {
                            symbol: permalink,
                            hash: hashStr
                        });
                    }
                    // fire rapid click on selection of item
                    beaconClick(
                        RAPID_SEC,
                        selectedSuggestion.symbol,
                        Object.assign(
                            {},
                            {
                                subsec: RAPID_SUBSEC,
                                itc: 0,
                                elm: 'itm',
                                elmt: 'ct',
                                rspns: 'nav',
                                ct: 'quote',
                                cat,
                                g: selectedSuggestion.symbol,
                                cpos: index,
                                _p: positionIndex,
                                pp: { query: this.query },
                                tar: window.location.hostname || DEFAULT_TAR,
                                // eslint-disable-next-line camelcase
                                tar_uri: targetUrl
                            },
                            i13nModel
                        ),
                        () => {
                            // callback
                            if (selectedSuggestion.isYahooFinance === false) {
                                // if its a private company, then simply close the overlay and navigate

                                // navigate to the private company page
                                window.location.assign(targetUrl);
                                return;
                            }
                            // otherwise, do the callback if present
                            callback({
                                index,
                                type: SCTN_TYPE_QUOTES,
                                url: targetUrl,
                                symbol: selectedSuggestion.symbol,
                                name:
                                    selectedSuggestion.longName ||
                                    selectedSuggestion.shortname ||
                                    selectedSuggestion.symbol,
                                query: this.query
                            });
                        }
                    );
                }
                break;
            case SCTN_TYPE_NEWS:
                {
                    const targetUrl = generateURL('link', { link: selectedSuggestion.link });
                    // send a beacon click
                    beaconClick(
                        RAPID_SEC,
                        'fin-news-ln',
                        Object.assign(
                            {},
                            {
                                subsec: RAPID_SUBSEC,
                                itc: 0,
                                elm: 'itm',
                                elmt: 'link',
                                rspns: 'nav',
                                ct: selectedSuggestion.type.toLowerCase(),
                                cat: '',
                                _p: positionIndex,
                                g: selectedSuggestion.uuid,
                                cpos: index,
                                pp: { query: this.query },
                                tar: window.location.hostname || DEFAULT_TAR,
                                // eslint-disable-next-line camelcase
                                tar_uri: targetUrl
                            },
                            i13nModel
                        ),
                        () => {
                            // close the overlay
                            // navigate to the news article
                            window.location.assign(targetUrl);
                        }
                    );
                }
                break;
            case SCTN_TYPE_NAV:
                {
                    const { href } = getSuggestionNavItemInfo(selectedSuggestion);
                    const navType = (
                        selectedSuggestion.navType || selectedSuggestion.navName
                    ).toLowerCase();
                    const targetUrl = generateURL('link', { link: href, useAbsolute: true });
                    // send a beacon click
                    beaconClick(
                        RAPID_SEC,
                        `fin-srch-${navType}`,
                        Object.assign(
                            {},
                            {
                                subsec: RAPID_SUBSEC,
                                itc: 0,
                                elm: 'itm',
                                elmt: 'link',
                                rspns: 'nav',
                                ct: navType === 'multiquote' ? navType : 'nav_link',
                                cat: '',
                                _p: positionIndex,
                                g: selectedSuggestion.symbols
                                    ? selectedSuggestion.symbols.join(',')
                                    : '',
                                cpos: index,
                                pp: { query: this.query },
                                tar: window.location.hostname || DEFAULT_TAR,
                                // eslint-disable-next-line camelcase
                                tar_uri: targetUrl
                            },
                            i13nModel
                        ),
                        () => {
                            // close the overlay
                            // navigate to the nav link
                            window.location.assign(targetUrl);
                        }
                    );
                }
                break;
            case SCTN_TYPE_LIST:
                {
                    const targetUrl =
                        selectedSuggestion.type === 'ALGO_WATCHLIST'
                            ? generateURL('algowatchlist', {
                                  brandSlug: selectedSuggestion.brandSlug,
                                  slug: selectedSuggestion.slug
                              })
                            : generateURL('screener', {
                                  id: selectedSuggestion.id
                              });

                    // send a beacon click
                    beaconClick(
                        RAPID_SEC,
                        `fin-lists-${
                            selectedSuggestion.type === 'ALGO_WATCHLIST' ? 'wl' : 'scr'
                        }`,
                        Object.assign(
                            {},
                            {
                                subsec: RAPID_SUBSEC,
                                itc: 0,
                                elm: 'itm',
                                elmt: 'link',
                                rspns: 'nav',
                                ct: selectedSuggestion.type.toLowerCase(),
                                cat: '',
                                _p: positionIndex,
                                g: positionIndex,
                                cpos: index,
                                pp: { query: this.query },
                                tar: window.location.hostname || DEFAULT_TAR,
                                // eslint-disable-next-line camelcase
                                tar_uri: targetUrl
                            },
                            i13nModel
                        ),
                        () => {
                            // close the overlay
                            // navigate to the news article
                            window.location.assign(targetUrl);
                        }
                    );
                }
                break;
            case SCTN_TYPE_RECOMMEND:
                {
                    // Recommended Ticker Item
                    const selectedRecommendation =
                        this.recommendations && this.recommendations[index];
                    if (selectedRecommendation) {
                        const targetUrl = generateURL('quote', {
                            symbol: selectedRecommendation.symbol
                        });
                        // send a beacon click
                        beaconClick(
                            RAPID_SEC,
                            selectedRecommendation.symbol,
                            Object.assign(
                                {},
                                {
                                    subsec: RAPID_SUBSEC,
                                    itc: 0,
                                    elm: 'itm',
                                    elmt: 'ct',
                                    rspns: 'nav',
                                    ct: 'trend-quotes',
                                    cat: '',
                                    _p: positionIndex,
                                    g: selectedRecommendation.symbol,
                                    cpos: index,
                                    pp: { query: '' },
                                    tar: window.location.hostname || DEFAULT_TAR,
                                    // eslint-disable-next-line camelcase
                                    tar_uri: targetUrl
                                },
                                i13nModel
                            ),
                            () => {
                                // do the callback
                                callback({
                                    index,
                                    type: SCTN_TYPE_RECOMMEND,
                                    url: targetUrl,
                                    symbol: selectedRecommendation.symbol,
                                    name:
                                        selectedRecommendation.longName ||
                                        selectedRecommendation.shortname ||
                                        selectedRecommendation.symbol,
                                    query: this.query
                                });
                            }
                        );
                    }
                }
                break;
            case SCTN_TYPE_RESEARCH_REPORTS:
                {
                    const targetUrl = generateURL('researchReports', {
                        reportId: encodeURIComponent(selectedSuggestion.id)
                    });
                    // send a beacon click
                    beaconClick(
                        RAPID_SEC,
                        'fin-rsrch-rprt',
                        Object.assign(
                            {},
                            {
                                subsec: RAPID_SUBSEC,
                                itc: 0,
                                elm: 'itm',
                                elmt: 'link',
                                rspns: 'nav',
                                ct: 'rsrch-rpt',
                                cat: '',
                                _p: positionIndex,
                                g: selectedSuggestion.id,
                                cpos: index,
                                pp: { query: this.query },
                                tar: window.location.hostname || DEFAULT_TAR,
                                // eslint-disable-next-line camelcase
                                tar_uri: targetUrl
                            },
                            i13nModel
                        ),
                        () => {
                            // close the overlay and navigate to the research report
                            window.location.assign(targetUrl);
                        }
                    );
                }
                break;
        }
    }
    /**
     * Updates selected index based on the action
     * @method updateSelectedIndex
     * @memberof FinSuggestionList
     * @param {String} action action to update the selected index. Can be add,sub,reset
     * @param {Function} [callback] callback after the update
     * @public
     */
    updateSelectedIndex(action, callback) {
        const isRecommendation = !this.suggestions && this.query === '';
        const itemList = this.container.querySelectorAll(
            `[data-id="${isRecommendation ? RECOMMENDATION_LIST_ID : SUGGESTION_LIST_ID}"] li`
        );
        // Get updated index info
        const { index, suggestion } = getNewUpdatedIndexObj(action, {
            currentIndex: this.selectedIndex,
            suggestions: this.suggestions,
            recommendations: this.recommendations,
            type: this.type,
            isRecommendation,
            totalItems: itemList.length
        });

        if (itemList) {
            if (itemList[this.selectedIndex]) {
                itemList[this.selectedIndex].classList.remove(cssModules.selectedBackground);
            }

            if (itemList[index]) {
                itemList[index].classList.add(cssModules.selectedBackground);
                this.input?.setAttribute('aria-activedescendant', itemList[index].id);
            }
        }

        this.selectedIndex = index;
        if (typeof callback === 'function') {
            // callback with the suggestion
            callback({ suggestion });
        }
    }
    /**
     * Clears suggestions and query
     * @method clear
     * @memberof FinSuggestionList
     * @public
     */
    clear() {
        // clean up container
        const suggestionNode = this.container.querySelector(
            `[data-id="${SUGGESTION_LIST_ID}"]`
        );
        while (suggestionNode.firstChild) {
            suggestionNode.removeChild(suggestionNode.firstChild);
        }
        // clear suggestions and query
        this.query = '';
        this.suggestions = null;
    }
    /**
     * Resets this instance
     * @method reset
     * @memberof FinSuggestionList
     * @public
     */
    reset() {
        this.hideSuggestions();
        this.clear();
        const suggestionNode = this.container.querySelector(
            `[data-id="${SUGGESTION_LIST_ID}"]`
        );
        suggestionNode.removeEventListener('click', this.onSelect);
        suggestionNode.removeEventListener('keydown', this.onKeyDown);
        const recommendationNode = this.container.querySelector(
            `[data-id="${RECOMMENDATION_LIST_ID}"]`
        );
        if (recommendationNode) {
            this.recommendations = null;
            recommendationNode.removeEventListener('click', this.onSelect);
            recommendationNode.removeEventListener('keydown', this.onKeyDown);
            // remove the recommendation node we added.
            recommendationNode.parentNode.removeChild(recommendationNode);
        }

        if (this.clearBtn) {
            this.clearBtn.removeEventListener('click', this.onClear);
        }
    }

    /**
     * Hides suggestion list
     * @method hideSuggestions
     * @memberof FinSuggestionList
     * @public
     */
    hideSuggestions() {
        if (this.container) {
            document.removeEventListener('click', this.onDocumentClick);
            this.container.classList.add(cssModules.noDisplay);
            if (this.clearBtn) {
                this.clearBtn.classList.add(cssModules.noDisplay);
            }
            // change aria-expanded
            this.formNode
                .querySelector('[aria-expanded]')
                ?.setAttribute('aria-expanded', 'false');
        }
    }
    /**
     * Returns current suggestions
     * @method getSuggestions
     * @memberof FinSuggestionList
     * @public
     * @returns {Object} current suggestions data
     */
    getSuggestions() {
        return this.suggestions;
    }

    /**
     * Shows suggestion list
     * @method showSuggestions
     * @memberof FinSuggestionList
     * @public
     */
    showSuggestions() {
        if (this.container) {
            document.addEventListener('click', this.onDocumentClick);
            this.container.classList.remove(cssModules.noDisplay);
            if (this.clearBtn && this.input?.value) {
                this.clearBtn.classList.remove(cssModules.noDisplay);
            }
            // change aria-expanded
            this.formNode
                .querySelector('[aria-expanded]')
                ?.setAttribute('aria-expanded', 'true');
        }
    }

    /**
     * Function to render the section header
     * @method renderSectionHeader
     * @memberof FinSuggestionList
     * @param {String} sectionType the section type
     * @return {Node|null} the dom node of section header or null if there is no section title
     * @private
     */
    renderSectionHeader(sectionType) {
        // Get section config for this section
        const sectionConfig = SRCH_TYPE_CONFIG[sectionType] || {};
        if (sectionConfig?.title) {
            // if there is a title, only then render a section header
            const sectionHeaderNode = document.createElement('div');
            sectionHeaderNode.className = `${cssModules.sectionHeader} ${
                this.enableModernTheme ? cssModules.sectionHeaderModern : ''
            }`;
            const sectionHeader = document.createElement('h3');
            sectionHeader.className = cssModules.sectionHeaderTitle;
            sectionHeader.textContent = getFormattedMessage(sectionConfig.title);
            sectionHeaderNode.appendChild(sectionHeader);

            if (sectionType === SCTN_TYPE_QUOTES && this.type !== SRCH_TYPES.QUOTE) {
                // Only for Quote section
                // Add screener button
                const screenerTextMessage = getFormattedMessage('SCREENER_STOCKS');
                const screenerLink = document.createElement('button');
                screenerLink.className = cssModules.screenerLink;
                screenerLink.setAttribute('role', 'link');
                screenerLink.setAttribute('type', 'button');
                screenerLink.setAttribute('data-id', SCREENER_LINK_ID);
                screenerLink.setAttribute('title', screenerTextMessage);
                screenerLink.addEventListener('click', this.onScreenerClick);

                const screenerText = document.createElement('span');
                screenerText.className = cssModules.screenerText;
                screenerText.textContent = screenerTextMessage;
                screenerLink.appendChild(screenerText);
                sectionHeaderNode.appendChild(screenerLink);
            }

            return sectionHeaderNode;
        }
        return null;
    }

    /**
     * Function to render a section having items
     * @method renderSectionList
     * @memberof FinSuggestionList
     * @param {String} sectionType the type of the section. eg: quotes, news, etc
     * @param {Object[]} items the items in the section
     * @param {Number} sectionIndex the index of the section
     * @return {Node} the dom node of section list
     * @private
     */
    renderSectionList(sectionType, items, sectionIndex) {
        const resultListFragment = document.createDocumentFragment(); // use document fragment for better performance
        let itemLength = items.length;
        let Item = null;
        let className;
        switch (sectionType) {
            case SCTN_TYPE_NAV:
                // Nav Item
                Item = NavSuggestionItem;
                break;
            case SCTN_TYPE_QUOTES:
                // Quote Item
                Item = QuoteSuggestionItem;
                className = cssModules.quoteItem;
                itemLength = Math.min(
                    (SRCH_URL_CONF[this.type] && SRCH_URL_CONF[this.type].quotesCount) ||
                        itemLength,
                    itemLength
                );
                break;
            case SCTN_TYPE_NEWS:
                // News Item
                Item = NewsSuggestionItem;
                itemLength = Math.min(
                    (SRCH_URL_CONF[this.type] && SRCH_URL_CONF[this.type].newsCount) ||
                        itemLength,
                    itemLength
                );
                break;
            case SCTN_TYPE_LIST:
                // List Item
                Item = ListSuggestionItem;
                break;
            case SCTN_TYPE_RECOMMEND:
                // Recommend Item
                Item = RecommendSuggestionItem;
                break;
            case SCTN_TYPE_RESEARCH_REPORTS:
                // Research Item
                Item = ResearchReportsSuggestionItem;
                break;
        }

        if (Item) {
            className = `${className ? className : ''} ${
                this.enableModernTheme ? cssModules.modernSuggestionItem : ''
            }`;
            // Add the results
            for (let index = 0; index < itemLength; index++) {
                // Now add this item to the result list
                resultListFragment.appendChild(
                    new Item({
                        query: this.query,
                        suggestion: items[index],
                        className,
                        positionIndex: sectionIndex + index,
                        index,
                        searchType: this.type
                    }).render()
                );
            }
        }

        const resultNode = document.createElement('ul');
        resultNode.setAttribute('role', 'listbox');
        resultNode.className = cssModules.list;
        resultNode.appendChild(resultListFragment);
        return resultNode;
    }

    /**
     * Function to render the search results
     * @method render
     * @memberof FinSuggestionList
     * @private
     */
    render() {
        const suggestionNode = this.container?.querySelector(
            `[data-id="${SUGGESTION_LIST_ID}"]`
        );
        if (!suggestionNode) {
            return;
        }

        const footerNode = this.container?.querySelector(`[data-id="${FOOTER_ID}"]`);
        if (!footerNode.classList.contains(cssModules.noDisplay)) {
            footerNode.classList.add(cssModules.noDisplay);
            suggestionNode.classList.remove(cssModules.BoxShadow);
        }
        // Add recommendation list for type=all and type=researchReports to show at all times when query is empty
        if (this.enableRecommendation) {
            let recommendationNode = this.container?.querySelector(
                `[data-id="${RECOMMENDATION_LIST_ID}"]`
            );
            if (!recommendationNode && this.recommendations) {
                // Add recommendation node
                recommendationNode = document.createElement('div');
                const useCustomStyle =
                    this.container.getAttribute(ATTR_CUSTOM_PANEL) ||
                    this.container.classList.contains(CLASS_CUSTOM_PANEL);
                if (!useCustomStyle) {
                    recommendationNode.className = cssModules.suggestionList;
                    recommendationNode.style.top = `${
                        this.input ? this.input.offsetHeight : DEFAULT_TOP_STYLE_RESULTS
                    }px`; // Add appropriate top style
                }

                recommendationNode.setAttribute('data-id', RECOMMENDATION_LIST_ID);
                recommendationNode.addEventListener('click', this.onSelect);
                recommendationNode.addEventListener('keydown', this.onKeyDown);
                recommendationNode.appendChild(this.renderSectionHeader(SCTN_TYPE_RECOMMEND));
                recommendationNode.appendChild(
                    this.renderSectionList(
                        this.type === SRCH_TYPES.QUOTE
                            ? SCTN_TYPE_QUOTES
                            : SCTN_TYPE_RECOMMEND,
                        this.recommendations,
                        0
                    )
                );
                this.container.appendChild(recommendationNode);
            }

            if (recommendationNode) {
                if (!this.query) {
                    // Empty state, show Recommended Tickers
                    recommendationNode.classList.remove(cssModules.noDisplay);
                    return;
                }
                // else hide the recommendation node
                recommendationNode.classList.add(cssModules.noDisplay);
                suggestionNode.classList.add(cssModules.BoxShadow);
            }
        }
        const containerFragment = document.createDocumentFragment(); // use document fragment for better performance

        if (!this.suggestions || this.suggestions.count === 0) {
            // No Suggestions at all
            if (this.type !== SRCH_TYPES.ALL && this.type !== SRCH_TYPES.RESEARCH_REPORTS) {
                // if type is something else, don't render anything
                return;
            }

            const iconNode = document.createElement('div');
            iconNode.className = cssModules.errorIcon;
            iconNode.appendChild(new Icon({ icon: AttentionIcon }).render());

            const msgNode = document.createElement('div');
            msgNode.className = cssModules.errorMessage;
            msgNode.textContent = getFormattedMessage('MSG_NO_SEARCH_RESULTS', {
                query: decodeURIComponent(this.query || '')
            });
            containerFragment.appendChild(iconNode);
            containerFragment.appendChild(msgNode);
            // Show the footer
            footerNode.classList.remove(cssModules.noDisplay);
        } else {
            // We have suggestions
            const searchSections =
                SRCH_SECTION_CONF[this.type] || SRCH_SECTION_CONF[SRCH_TYPES.ALL];
            searchSections.forEach((section, sectionIndex) => {
                const sectionItems = (this.suggestions && this.suggestions[section]) || [];
                if (sectionItems.length > 0) {
                    // only show the section if there is atleast one item in the section
                    // render the section header
                    const headerSection = this.renderSectionHeader(section);
                    if (headerSection) {
                        containerFragment.appendChild(headerSection);
                    }
                    // render the items list
                    containerFragment.appendChild(
                        this.renderSectionList(section, sectionItems, sectionIndex)
                    );
                }
            });
            if (this.type === SRCH_TYPES.QUOTE) {
                if (
                    this.container.hasAttribute(ATTR_SHOW_FOOTER) ||
                    this.container.classList.contains(CLASS_SHOW_FOOTER)
                ) {
                    const tip = footerNode?.querySelector('p');
                    tip.textContent = getFormattedMessage('QUOTE_LOOKUP_TIP');
                    tip.className = `${cssModules.tipMessage} ${cssModules.modernSuggestionItem}`;
                    footerNode.classList.remove(cssModules.noDisplay);
                    const showAll = document.createElement('div');
                    showAll.className = cssModules.linkItem;
                    const link = document.createElement('a');
                    link.textContent = getFormattedMessage('SHOW_RESULTS_FOR_QUERY', {
                        query: decodeURIComponent(this.query || '')
                    });
                    link.href = generateURL('lookup', {
                        query: this.query
                    });
                    link.className = cssModules.showAllLink;
                    showAll.appendChild(link);
                    containerFragment.appendChild(showAll);
                }
            }
        }
        // Add the container fragment
        suggestionNode.appendChild(containerFragment);
    }
}

export default FinSuggestionList;
