export const MARK_FIRST_SEARCH_KEYUP = 'Fin.Search first keyup';
export const MARK_FIRST_SEARCH_AHEAD = 'Fin.Search first searchahead';
export const MARK_SEARCH_ACTIVE = 'Fin.Search active';
export const MEASURE_FIRST_SEARCH_AHEAD = 'Fin.Search first searchahead delay';
export const MEASURE_SEARCH_ACTIVE = 'Fin.Search active delay';

const winPerf = typeof window !== 'undefined' && window.performance;
const perf =
    winPerf &&
    winPerf.mark &&
    winPerf.getEntriesByName &&
    winPerf.measure &&
    winPerf.clearMarks
        ? winPerf
        : null;

/**
 * Send performance beacon
 * @param {Object} userTime userTime object
 */
function sendPerfBeacon(userTime) {
    if (typeof window?.rapidInstance?.beaconPerformanceData === 'function') {
        // send a performance beacon
        window.rapidInstance.beaconPerformanceData({
            // eslint-disable-next-line camelcase
            perf_usertime: {
                utm: userTime
            }
        });
    }
}

/**
 * Send performance beacon by given measurements
 * @param {Array} measurements measurement array
 * @param {Object} types type object
 * @param {Boolean} types.start true to enable start time beaconing
 * @param {Boolean} types.stop true to enable stop time beaconing
 * @param {Boolean} types.duration true to enable duration time beaconing
 */
export function sendPerfBeaconByMeasurements(measurements, types) {
    types = types || {};
    const userTime = {};
    if (!(measurements?.length > 0)) {
        return;
    }
    measurements.forEach((measurement) => {
        if (measurement?.startTime > -1) {
            if (types.start) {
                userTime[`${measurement.name} START`] = Math.round(measurement.startTime);
            }
            if (types.stop) {
                userTime[`${measurement.name} STOP`] = Math.round(
                    measurement.startTime + measurement.duration
                );
            }
            if (types.duration) {
                userTime[`${measurement.name} DUR`] = Math.round(measurement.duration);
            }
        }
    });
    sendPerfBeacon(userTime);
}

/**
 * Mark a user timing event only once.
 * @param {String} markName The name of the timing mark
 * @return {Boolean} Returns true if the mark is successful, false otherwise.
 */
export function markOnce(markName) {
    if (perf && markName) {
        if (perf.getEntriesByName(markName).length === 0) {
            perf.mark(markName);
            return true;
        }
    }
    return false;
}

/**
 * Get the measurement and send performance beacon
 * @param {Array} measurementKeys array of measurement keys
 * @param {Object} types type object
 * @param {Boolean} types.start true to enable start time beaconing
 * @param {Boolean} types.stop true to enable stop time beaconing
 * @param {Boolean} types.duration true to enable duration time beaconing
 */
function getMeasurementsAndSendPerfBeacon(measurementKeys, types) {
    const measurements = [];
    measurementKeys.forEach((key) => {
        const measurement =
            perf.getEntriesByName(key, 'measure') &&
            perf.getEntriesByName(key, 'measure').pop();
        if (measurement) {
            measurements.push(measurement);
        }
    });
    sendPerfBeaconByMeasurements(measurements, types);
}

/**
 * Mark and measures when search box becomes interactive.
 */
export function measureSearchActive() {
    if (perf && markOnce(MARK_SEARCH_ACTIVE)) {
        const measure = perf.getEntriesByName(MEASURE_SEARCH_ACTIVE);
        if (measure.length > 0) {
            return;
        }
        const [pageStartMark] = perf.getEntriesByName('PageStart'); // set by touchdown
        const [activeMark] = perf.getEntriesByName(MARK_SEARCH_ACTIVE);
        if (pageStartMark && activeMark) {
            perf.measure(MEASURE_SEARCH_ACTIVE, 'PageStart', MARK_SEARCH_ACTIVE);
            getMeasurementsAndSendPerfBeacon([MEASURE_SEARCH_ACTIVE], {
                start: true,
                stop: true,
                duration: true
            });
        }
    }
}

/**
 * Mark and measure user perceived first search delay, when user begines typing
 * in search box before the box becomes interactive.
 * @param {Boolean} hasInput Whether the search input has text (not empty)
 */
export function measureSearchAheadDelay(hasInput) {
    // mark search ahead time in perf user timing
    if (perf && markOnce(MARK_FIRST_SEARCH_AHEAD)) {
        if (hasInput) {
            // only measure the first search ahead delay if the search input is not empty
            const measure = perf.getEntriesByName(MEASURE_FIRST_SEARCH_AHEAD);
            if (measure.length > 0) {
                return;
            }
            const [keyupMark] = perf.getEntriesByName(MARK_FIRST_SEARCH_KEYUP);
            const [activeMark] = perf.getEntriesByName(MARK_SEARCH_ACTIVE);
            const [firstACMark] = perf.getEntriesByName(MARK_FIRST_SEARCH_AHEAD);
            if (
                keyupMark &&
                activeMark &&
                firstACMark &&
                keyupMark.startTime <= activeMark.startTime
            ) {
                perf.measure(
                    MEASURE_FIRST_SEARCH_AHEAD,
                    MARK_FIRST_SEARCH_KEYUP,
                    MARK_FIRST_SEARCH_AHEAD
                );
                getMeasurementsAndSendPerfBeacon([MEASURE_FIRST_SEARCH_AHEAD], {
                    start: true,
                    stop: true,
                    duration: true
                });
            }
        }
    }
}
