import VideoIcon from '@vzmi/svg-icons/icons/video';
import NewsIcon from '@vzmi/svg-icons/icons/news';
import Icon from './Icon';
import { SCTN_TYPE_NEWS, decode, getRelativeTimeString } from './utils';
import cssModules from '../styles/modules.css';
import { SuggestionItem } from './SuggestionItem';

/**
 * class to render the news suggestion item
 * @class NewsSuggestionItem
 * @extends SuggestionItem
 */
class NewsSuggestionItem extends SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {Number} options.index item index for this item
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     */
    constructor(options) {
        super(options);
        this.type = SCTN_TYPE_NEWS;
        this.testId = 'srch-news';
        this.title = decode(this.suggestion.title || '');
    }

    /**
     * Helper function to render the news suggestion item
     * @method render
     * @memberof NewsSuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        const isVideoType = this.suggestion.type === 'VIDEO';

        const linkItem = super.render();

        const leftColumn = document.createElement('div');
        const rightColumn = document.createElement('div');
        leftColumn.className = cssModules.itemLeftCol;
        rightColumn.className = cssModules.itemRightCol;

        const iconDiv = document.createElement('div');
        iconDiv.className = cssModules.itemIconContainer;
        iconDiv.appendChild(
            new Icon({
                icon: isVideoType ? VideoIcon : NewsIcon,
                className: isVideoType ? cssModules.newsVideoIcon : cssModules.newsIcon,
                viewBox: isVideoType ? '0 0 48 48' : '0 0 24 24'
            }).render()
        );
        leftColumn.appendChild(iconDiv);

        const newsTitleDiv = document.createElement('h5');
        newsTitleDiv.className = cssModules.newsTitle;
        newsTitleDiv.textContent = this.title;

        const newsDiv = document.createElement('div');
        newsDiv.className = cssModules.newsMeta;

        const publisher = document.createElement('span');
        publisher.textContent = decode(this.suggestion.publisher || '');

        newsDiv.appendChild(publisher);
        const seperator = document.createElement('i');
        seperator.setAttribute('aria-hidden', 'true');
        seperator.className = cssModules.newsSeperator;
        seperator.innerHTML = '•';
        newsDiv.appendChild(seperator);

        const publishTime = document.createElement('span');
        publishTime.textContent = getRelativeTimeString(
            this.suggestion.providerPublishTime * 1000
        );
        newsDiv.appendChild(publishTime);

        rightColumn.appendChild(newsTitleDiv);
        rightColumn.appendChild(newsDiv);

        linkItem.appendChild(leftColumn);
        linkItem.appendChild(rightColumn);
        return linkItem;
    }
}

export default NewsSuggestionItem;
