import { SCTN_TYPE_LIST, decode, getFormattedMessage } from './utils';
import Icon from './Icon';
import FilterIcon from '@vzmi/svg-icons/icons/filter';
import ListIcon from '@vzmi/svg-icons/icons/list';
import cssModules from '../styles/modules.css';
import { SuggestionItem } from './SuggestionItem';

/**
 * class to render the list suggestion item
 * @class ListSuggestionItem
 * @extends SuggestionItem
 */
class ListSuggestionItem extends SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {Number} options.index item index for this item
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     */
    constructor(options) {
        super(options);
        this.type = SCTN_TYPE_LIST;
        this.title = decode(this.suggestion.name || this.suggestion.title || '');
        this.testId = 'srch-list';
    }

    /**
     * Helper function to render the list suggestion item
     * @method render
     * @memberof ListSuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        const isAlgoWatchlist = this.suggestion.type === 'ALGO_WATCHLIST';
        const linkItem = super.render();

        const leftColumn = document.createElement('div');
        const rightColumn = document.createElement('div');
        leftColumn.className = cssModules.itemLeftCol;
        rightColumn.className = cssModules.itemRightCol;

        const iconDiv = document.createElement('div');
        iconDiv.className = cssModules.itemIconContainer;
        iconDiv.appendChild(
            new Icon({
                className: cssModules.listIcon,
                viewBox: !isAlgoWatchlist ? '0 0 48 48' : '0 0 24 24',
                icon: isAlgoWatchlist ? ListIcon : FilterIcon
            }).render()
        );
        leftColumn.appendChild(iconDiv);

        const titleDiv = document.createElement('h5');
        titleDiv.className = cssModules.listTitle;
        titleDiv.textContent = this.title;

        const attrDiv = document.createElement('div');
        attrDiv.className = cssModules.listAttr;
        attrDiv.textContent = getFormattedMessage(this.suggestion.type);

        rightColumn.appendChild(titleDiv);
        rightColumn.appendChild(attrDiv);

        linkItem.appendChild(leftColumn);
        linkItem.appendChild(rightColumn);
        return linkItem;
    }
}

export default ListSuggestionItem;
