export default class Icon {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.icon icon to render
     * @param {String} options.icon.path icon svg path
     * @param {String} [options.viewBox=0 0 48 48] view box value for this icon
     * @param {Number} [options.width=16] icon width in px
     * @param {Number} [options.height=16] icon height in px
     * @param {String} [options.className] icon classname
     */
    constructor({ icon, viewBox = '0 0 48 48', width = 16, height = 16, className = '' }) {
        this.icon = icon;
        this.viewBox = viewBox;
        this.width = width;
        this.height = height;
        this.className = className;
    }

    /**
     * function which renders the icon and returns the node
     * @memberof Icon
     * @method render
     * @returns {Node} svg Dom node having the icon
     */
    render() {
        const iconNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        iconNode.setAttribute('viewBox', this.viewBox);
        iconNode.setAttribute('height', this.height);
        iconNode.setAttribute('width', this.width);
        iconNode.setAttribute('class', this.className);
        iconNode.style.strokeWidth = 0;
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', this.icon.path);
        iconNode.appendChild(path);
        return iconNode;
    }
}
