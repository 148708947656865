import cssModules from '../styles/modules.css';
/**
 * class to render a suggestion item
 * @class SuggestionItem
 */
export class SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {Number} options.index item index for this item
     * @param {String} [options.query] query for quote suggestion
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     * @param {String} options.type suggestion item type. eg: SCTN_TYPE_NEWS, SCTN_TYPE_QUOTE
     * @param {String} options.searchType type of the search functionality.
     * @param {String} [options.testId] test id for this suggestion item type
     * @param {String} [options.title] any title for this item, set up as `title` attribute (shows up as tooltip on hover)
     */
    constructor({
        suggestion,
        index,
        query,
        positionIndex,
        className = '',
        type,
        searchType,
        testId,
        title
    }) {
        this.query = query;
        this.suggestion = suggestion;
        this.index = index;
        this.positionIndex = positionIndex;
        this.className = className;
        this.type = type;
        (this.searchType = searchType), (this.testId = testId);
        this.title = title;
    }

    /**
     * Helper function to render the base suggestion item
     * @method render
     * @memberof SuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        const linkItem = document.createElement('li');
        linkItem.id = `result-${this.type}-${this.index}`;
        linkItem.setAttribute('role', 'option');
        linkItem.setAttribute('title', this.title);
        linkItem.setAttribute('tabindex', '0');
        linkItem.setAttribute('data-type', this.type);
        linkItem.setAttribute('data-test', this.testId);
        linkItem.setAttribute('data-index', this.index);
        linkItem.setAttribute('data-pindex', this.positionIndex);
        linkItem.className = `${cssModules.linkItem}${
            this.className ? ` ${this.className}` : ''
        }`;
        return linkItem;
    }
}
