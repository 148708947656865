import FinSearch from './FinSearch';

// Constants
const ATTR_INIT = 'finInit'; // present if form is initialized
const ATTR_FIN_SRCH = 'data-finsrch';
const CLASS_FIN_SRCH = 'finsrch-form';

// polyfill for element matches for < IE 9
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
            const matches = (this.document || this.ownerDocument).querySelectorAll(s);
            let i = matches.length;
            // eslint-disable-next-line no-empty
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
        };
}

/**
 * Class for handling all the Search functionality
 * @class FinanceSearchJS
 */
class FinanceSearchJS {
    /**
     * @constructor
     */
    constructor() {
        this.config = {};
        this.searchList = [];
    }

    /**
     * Function to init and add event handlers to all the search forms
     * @method initSearchForms
     * @memberof FinanceSearchJS
     * @private
     */
    initSearchForms() {
        // go through all the forms having finance search initializers
        // and add them to our list
        const formNodes = Array.from(
            new Set([
                ...Array.from(document.body.querySelectorAll(`form[${ATTR_FIN_SRCH}]`)),
                ...Array.from(document.body.querySelectorAll(`form.${CLASS_FIN_SRCH}`))
            ])
        );
        for (let i = 0; i < formNodes.length; i++) {
            const formNode = formNodes[i];
            if (formNode.hasAttribute(ATTR_INIT)) {
                continue;
            }
            this.initSearchForm({ formNode });
        }
    }

    /**
     * Function to init and add event handlers for the given form node
     * @method initSearchForm
     * @memberof FinanceSearchJS
     * @param {Object} [config={}] configuration object
     * @param {HTMLFormElement} [config.formNode] form node
     * @param {Object} [config.searchConfig] search config
     * @param {Function} [config.onCancelCb] on-cancel callback
     * @param {Function} [config.onFocusCb] on-focus callback
     * @param {Function} [config.onSelectCb] on-select callback
     */
    initSearchForm(config = {}) {
        const { formNode, searchConfig, onCancelCb, onFocusCb, onSelectCb } = config;
        if (!formNode) {
            return;
        }
        // if form is initialized just add callback
        if (formNode.hasAttribute(ATTR_INIT)) {
            this.addCallbacks(config);
            return;
        }
        const finSrchType = formNode.getAttribute(ATTR_FIN_SRCH) || 'all';
        formNode.setAttribute(ATTR_INIT, '');
        const finSearchObj = new FinSearch({
            config: { ...this.config, ...searchConfig },
            form: formNode,
            onCancelCb,
            onFocusCb,
            onSelectCb,
            type: finSrchType
        });
        finSearchObj.addEventListeners(); // add event listener for this search
        this.searchList.push(finSearchObj);
    }

    /**
     * Function to add callbacks for specific formNode
     * @method addCallbacks
     * @memberof FinanceSearchJS
     * @param {DOM} [formNode] form node
     * @param {Function} [onCancelCb] on-cancel callback
     * @param {Function} [onFocusCb] on-focus callback
     * @param {Function} [onSelectCb] on-select callback
     */
    addCallbacks({ formNode, onCancelCb, onFocusCb, onSelectCb } = {}) {
        if (!formNode) {
            return;
        }
        const finSearch = this.searchList.find((finSearch) => finSearch.formNode === formNode);
        if (finSearch) {
            if (typeof onCancelCb === 'function') {
                finSearch.addOnCancelCb(onCancelCb);
            }
            if (typeof onFocusCb === 'function') {
                finSearch.addOnFocusCb(onFocusCb);
            }
            if (typeof onSelectCb === 'function') {
                finSearch.addOnSelectCb(onSelectCb);
            }
        }
    }

    /**
     * Function to reset the Finance search JS instance on the page
     * @method reset
     * @memberof FinanceSearchJS
     * @public
     */
    reset() {
        for (let i = 0; i < this.searchList.length; i++) {
            if (this.searchList[i]) {
                // cleanup
                this.searchList[i].reset();
            }
        }
        // empty search list
        this.searchList = [];
    }

    /**
     * Function to destroy the search instance of the given form
     * @method destroy
     * @param {DOM} [formNode] form node
     * @memberof FinanceSearchJS
     * @public
     */
    destroyForm(formNode) {
        if (!formNode) {
            return;
        }
        for (let i = 0; i < this.searchList.length; i++) {
            if (this.searchList[i].formNode === formNode) {
                // cleanup and remove the instance
                this.searchList[i].reset();
                this.searchList.splice(i, 1);
            }
        }
    }

    /**
     * Function to initialize the Finance search JS instance on the page
     * @method reset
     * @memberof FinanceSearchJS
     * @param {Object} [config] search config passed from Application
     * @public
     */
    init(config = {}) {
        this.config = config;
        this.initSearchForms();
    }
}

// eslint-disable-next-line no-undef
window.finSearchContext = { lang: '__LANG__', region: '__REGION__', intl: '__INTL__' };
// Set the instance on window object
window.FinSearch = new FinanceSearchJS();
// eslint-disable-next-line no-console
console.log('[FinSearchJS] Search initialized');
