import FinSuggestionList from './FinSuggestionList';
import {
    DEBOUNCE_TIMEOUT,
    FIN_SRCH_CONTEXT,
    RAPID_SEC,
    RAPID_SUBSEC,
    SRCH_TYPES,
    beaconClick,
    beaconEvent,
    beaconLinkViews,
    debouncePromise,
    getFormattedMessage,
    getQuerySuggestions,
    getTrendingTickers
} from './utils';
import {
    MARK_FIRST_SEARCH_KEYUP,
    markOnce,
    measureSearchActive,
    measureSearchAheadDelay
} from './perfUtil';

import cssModules from '../styles/modules.css';

const ATTR_FULL_WINDOW = 'fullWindow';
const ATTR_SRCH_INPUT = 'srchInput';
const ATTR_SRCH_BUTTON = 'srchBtn';
const ATTR_RES_CONT = 'srchResult';
const ATTR_DEBOUNCE_TIMEOUT = 'data-debounce';
const ATTR_ENABLE_PERF = 'enablePerf';
const ATTR_ENABLE_MODERN_THEME = 'modernTheme';
const ATTR_SHOW_RECOMMENDATION = 'srchShowRecomlst';
const CLASS_RES_CONT = 'finsrch-rslt';
const CLASS_FULL_WINDOW = 'finsrch-full-window';
const CLASS_SRCH_INPUT = 'finsrch-inpt';
const CLASS_SRCH_BUTTON = 'finsrch-btn';
const CLASS_ENABLE_PERF = 'finsrch-enable-perf';
const CLASS_ENABLE_MODERN_THEME = 'finsrch-modern-theme';
const CLASS_SHOW_RECOMMENDATION = 'finsrch-show-recomlst';

/**
 * A class instance for each search form functionality
 * @class FinanceSearch
 */
class FinanceSearch {
    /**
     * @constructor
     * @memberof FinanceSearch
     * @param {Object} options options for the constructor
     * @param {Node} options.form form DOM node
     * @param {String} [options.type=all] type of the search functionality
     * @param {Function} [options.onCancelCb] callback when user has closed the full screen experience
     * @param {Function} [options.onFocusCb] callback when user has focused on the form's input element
     * @param {Function} [options.onSelectCb] callback after when user has selected an item from the list
     * @param {Object} [options.config] the search config passed
     */
    constructor({ form, type = SRCH_TYPES.ALL, onCancelCb, onFocusCb, onSelectCb, config }) {
        this.formNode = form;
        this.type = type;
        this.input = null;
        this.isFullWindowExperience = false;
        this.srchBtn = null;
        this.showResults = false;
        this.config = config;
        this.onCancel = this.onCancel.bind(this);
        this.onCancelCb = onCancelCb;
        this.onFocusCb = onFocusCb;
        this.onSelectCb = onSelectCb;
        this.onInput = debouncePromise(
            this.onInput.bind(this),
            Number(form?.getAttribute(ATTR_DEBOUNCE_TIMEOUT) || DEBOUNCE_TIMEOUT)
        );
        this.onSubmit = this.onSubmit.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.init(); // initialize
    }

    /**
     * Initializes this instance
     * @method init
     * @memberof FinSuggestionList
     * @private
     */
    init() {
        if (this.formNode) {
            // set input node
            this.input =
                this.formNode.querySelector(`[${ATTR_SRCH_INPUT}]`) ||
                this.formNode.querySelector(`.${CLASS_SRCH_INPUT}`);
            if (!this.input) {
                // istanbul ignore next
                // eslint-disable-next-line no-console
                console.warn(
                    '[FinSearchJS] No input Node found, see documentation to configure input'
                );
                // istanbul ignore next
                return;
            }

            // set button node
            this.srchBtn =
                this.formNode.querySelector(`[${ATTR_SRCH_BUTTON}]`) ||
                this.formNode.querySelector(`.${CLASS_SRCH_BUTTON}`);
            // set search results container
            let resNode =
                this.formNode.querySelector(`[${ATTR_RES_CONT}]`) ||
                this.formNode.querySelector(`.${CLASS_RES_CONT}`);

            // Check if we want to enable modern visual look and feel.
            const enableModernTheme =
                this.formNode.hasAttribute(ATTR_ENABLE_MODERN_THEME) ||
                this.formNode.classList.contains(CLASS_ENABLE_MODERN_THEME);

            // Check if we want to enable the full window experience
            this.isFullWindowExperience =
                this.formNode.hasAttribute(ATTR_FULL_WINDOW) ||
                this.formNode.classList.contains(CLASS_FULL_WINDOW);

            if (!resNode) {
                // if result node is not there, then create it.
                resNode = document.createElement('div');
                resNode.setAttribute(ATTR_RES_CONT, true);
                this.formNode.appendChild(resNode);
            }
            const enableRecommendation =
                this.type === SRCH_TYPES.ALL ||
                this.type === SRCH_TYPES.RESEARCH_REPORTS ||
                resNode.classList.contains(CLASS_SHOW_RECOMMENDATION) ||
                resNode.hasAttribute(ATTR_SHOW_RECOMMENDATION);
            // setup the suggestion list
            this.suggestionList = new FinSuggestionList({
                container: resNode,
                type: this.type,
                formNode: this.formNode,
                input: this.input,
                isFullWindowExperience: this.isFullWindowExperience,
                enableModernTheme,
                enableRecommendation,
                onSelectCb: this.onSelect,
                onClearCb: this.onClear
            });
            if (enableRecommendation) {
                // Fetch recommendations initially
                this.fetchRecommendations();
            }
            if (this.isFullWindowExperience) {
                this.initFullWindowExperience();
            }
            // If there is any input already, handle it
            if (this.input.value) {
                this.handleInput(this.input.value);
            }
        } else {
            // istanbul ignore next
            // eslint-disable-next-line no-console
            console.warn('[FinSearchJS] No form Node provided');
        }
    }

    /**
     * Finds the search input and search button, and adds input listener on it.
     * @method addEventListeners
     * @memberof FinanceSearch
     * @public
     */
    addEventListeners() {
        // Check if performance beacons are allowed.
        const isPerformanceEnabled =
            this.formNode.hasAttribute(ATTR_ENABLE_PERF) ||
            this.formNode.classList.contains(CLASS_ENABLE_PERF);

        if (this.input) {
            if (isPerformanceEnabled) {
                // For performance measurement
                measureSearchActive();
                measureSearchAheadDelay(!!this.input.value);
                this.input.addEventListener('keyup', this.onKeyUp);
            }

            // add listeners for the input
            this.input.addEventListener('input', this.onInput);
            this.input.addEventListener('focus', this.onFocus);
            this.input.addEventListener('keydown', this.onKeyDown);
        }
        if (this.formNode) {
            this.formNode.addEventListener('submit', this.onSubmit);
        }
    }

    /**
     * Creates the backdrop and cancel button elements for the full window experience
     * @method initFullWindowExperience
     * @memberof FinanceSearch
     * @private
     */
    initFullWindowExperience() {
        if (this.formNode) {
            const div = document.createElement('div');
            div.classList.add(cssModules.backdrop, cssModules.noDisplay);
            this.formNode.parentElement.append(div);
            this.backdropDiv = div;
            const cancelBtn = document.createElement('button');
            const cancelMsg = getFormattedMessage('CANCEL');
            cancelBtn.setAttribute('type', 'button');
            cancelBtn.setAttribute('title', cancelMsg);
            cancelBtn.textContent = cancelMsg;
            cancelBtn.classList.add(cssModules.cancelBtn, cssModules.noDisplay);
            cancelBtn.addEventListener('click', this.onCancel);
            this.cancelBtn = cancelBtn;
            this.srchBtn.insertAdjacentElement('beforebegin', cancelBtn);
        }
    }
    /**
     * Fetches suggestions based on the query and loads the suggestions in memory for further processing
     * @method fetchSuggestions
     * @memberof FinanceSearch
     * @private
     * @param {String} query query to fetch the suggestions for
     * @param {Object} options further options
     */
    async fetchSuggestions(query) {
        const encodedQuery = encodeURIComponent(query);
        try {
            const suggestions = await getQuerySuggestions({
                config: this.config,
                type: this.type,
                query: encodedQuery
            });
            if (this.suggestionList) {
                // if suggestion list is present, then load suggestions and show
                this.suggestionList.loadSuggestions(encodedQuery, suggestions);
            }
        } catch (ex) {
            // We already sent the beacon while making the xhr request, hence just log the error here.
            // eslint-disable-next-line no-console
            console.error('[FinSearchJS] ', ex);
        }
    }

    /**
     * Fetches recommendations and updates the suggestion list with the recommendations
     * @method fetchRecommendations
     * @memberof FinanceSearch
     * @private
     */
    async fetchRecommendations() {
        try {
            const options = {
                fields: this.type === SRCH_TYPES.QUOTE ? 'shortName,longName' : '',
                region: (window[FIN_SRCH_CONTEXT] || {}).region
            };
            if (this.config?.recommendCount) {
                options.count = this.config.recommendCount;
            }
            const recommendations = await getTrendingTickers(options);
            this.suggestionList.loadRecommendations(recommendations); // set the recommendations
        } catch (ex) {
            // We already sent the beacon while making the xhr request, hence just log the error here.
            // istanbul ignore next
            // eslint-disable-next-line no-console
            console.error('[FinSearchJS] ', ex);
        }
    }

    /**
     * Handles change event on the input
     * @method onInput
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @private
     */
    async onInput(e) {
        await this.handleInput(e.target.value);
    }

    /**
     * Handles any selection. It can happen from either user selecting anything from the menu
     * @method onSelect
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @param {Object} options options for the callback
     * @private
     */
    onSelect({ index, type, url, symbol, name, query }) {
        if (typeof this.onSelectCb === 'function') {
            // do callback
            this.onSelectCb({ index, type, url, symbol, name, query });
            this.onClear();
            return;
        }
        // otherwise just navigate to the url
        window.location.assign(url);
    }

    /**
     * Handles clearing of input.
     * @method onClear
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @private
     */
    onClear() {
        this.showResults = false;
        this.suggestionList.clear();
        this.suggestionList.loadRecommendations(null); // to force render
    }

    /**
     * Handles submit event on the form
     * @method onSubmit
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @private
     */
    async onSubmit(e) {
        e.preventDefault();
        if (this.suggestionList) {
            const query = encodeURIComponent(this.input.value.trim());
            if (query && this.suggestionList.getSuggestions()?.query !== query) {
                // we haven't yet fetched the suggestions yet, so fetch suggestions for this query
                await this.fetchSuggestions(query);
                this.suggestionList.onSubmit(e);
            } else {
                // Just call onSubmit
                this.suggestionList.onSubmit(e);
            }
        }
    }

    /**
     * Handles cancel button click on the form
     * @method onCancel
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @private
     */
    onCancel(e) {
        e.preventDefault();
        // fire rapid click
        beaconClick(RAPID_SEC, 'cncl-click', {
            subsec: RAPID_SUBSEC,
            itc: 1,
            elm: 'itm',
            elmt: 'cl',
            outcm: 'srch-cncl'
        });
        this.onClear();
        this.suggestionList.hideSuggestions();
        this.cancelBtn.classList.add(cssModules.noDisplay);
        this.srchBtn.classList.remove(cssModules.noDisplay);
        this.formNode.classList.remove(cssModules.cover);
        this.backdropDiv.classList.add(cssModules.noDisplay);
        document.body.classList.remove(cssModules.noScroll);

        if (typeof this.onCancelCb === 'function') {
            this.onCancelCb();
        }
    }

    /**
     * Show the full window experience
     * @method enterFullWindow
     * @memberof FinanceSearch
     * @returns {void}
     * @private
     */
    enterFullWindow() {
        if (!this.cancelBtn || !this.backdropDiv) {
            this.initFullWindowExperience();
        }

        this.cancelBtn.classList.remove(cssModules.noDisplay);
        this.srchBtn.classList.add(cssModules.noDisplay);
        this.formNode.classList.add(cssModules.cover);
        this.backdropDiv.classList.remove(cssModules.noDisplay);
        document.body.classList.add(cssModules.noScroll);
    }

    /**
     * Handles focus event on the input
     * @method onFocus
     * @memberof FinanceSearch
     * @private
     */
    onFocus() {
        if (typeof this.onFocusCb === 'function') {
            this.onFocusCb();
        }

        if (this.isFullWindowExperience) {
            this.enterFullWindow();
        }

        if (this.suggestionList) {
            // show the suggestions
            this.suggestionList.showSuggestions();
        }
    }

    /**
     * Handles keydown event on the input
     * @method onKeyDown
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @private
     */
    onKeyDown(e) {
        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                // update selected index in SuggestionList
                if (this.suggestionList) {
                    this.suggestionList.updateSelectedIndex(
                        e.key === 'ArrowUp' ? 'sub' : 'add',
                        ({ suggestion }) => {
                            if (suggestion?.symbol) {
                                // for some reason, query API is returning {symbol: "null", ...} for story items.
                                // we need to check for this to avoid "null" showing in input field when
                                // users use arrow down/up to select news story items
                                const invalidSymbol =
                                    suggestion.type === 'STORY' &&
                                    suggestion.symbol === 'null';
                                if (!invalidSymbol) {
                                    this.input.value = suggestion.symbol;
                                }
                            } else if (
                                suggestion?.navType === 'MULTIQUOTE' &&
                                Array.isArray(suggestion.symbols)
                            ) {
                                const multiSymbols = suggestion.symbols.join(',');
                                if (multiSymbols) {
                                    this.input.value = multiSymbols;
                                }
                            }
                        }
                    );
                }

                // set the value for the suggestion on selected index in the form input
                // set cursor at the end for input text [FDW-1694]
                if (typeof this.input.selectionStart === 'number') {
                    setTimeout(() => {
                        this.input.selectionStart = this.input.selectionEnd =
                            this.input.value.length;
                    }, 0);
                }
                break;
            case 'Escape':
                // hide suggestions
                this.input.blur();
                if (this.suggestionList) {
                    this.suggestionList.hideSuggestions(e);
                }
                break;
        }
    }

    /**
     * handles the input query, gets the suggestions for the query
     * @method handleInput
     * @memberof FinanceSearch
     * @param {String} value the query for which we want to get the suggestions.
     * @private
     */
    async handleInput(value) {
        const inputQuery = value.trim(); // trim the text before trying to use it
        if (inputQuery.length > 0 && !this.showResults) {
            // fire the rapid click beacon only when the suggestion was hidden and value length is exactly 1 character, which is only there for search beginning.
            beaconClick(RAPID_SEC, 'inpt-fcs', {
                subsec: RAPID_SUBSEC,
                itc: 1,
                elm: 'inpt',
                elmt: 'srch',
                outcm: 'srch-menu-on'
            });
        }
        // fire a custom rapid event
        beaconEvent('fin-srch-inpt', {
            sec: RAPID_SEC,
            subsec: RAPID_SUBSEC,
            elm: 'kybrd',
            itc: 1,
            slk: inputQuery,
            outcm: 'inpt-ev'
        });

        if (!inputQuery) {
            // if the value is empty, clear suggestion list
            this.showResults = false;
            this.suggestionList.clear();
            this.suggestionList.loadRecommendations(null); // to force render
            return;
        }

        // otherwise show the suggestions
        this.showResults = true;

        // call auto complete query with the new value
        await this.fetchSuggestions(inputQuery);
        // now show the suggestions
        this.suggestionList.showSuggestions();

        // fire rapid link views beacon for research reports
        if (this.type === SRCH_TYPES.RESEARCH_REPORTS) {
            // only send beacon when reports are available
            if (this.suggestionList.getSuggestions()?.researchReports?.length > 0) {
                beaconLinkViews({
                    container: this.formNode,
                    selector: `li[data-type="${SRCH_TYPES.RESEARCH_REPORTS}"]`,
                    sec: RAPID_SEC,
                    subsec: 'rsrch-rprts'
                });
            }
        }
    }
    /**
     * Handles keyup event on the input
     * @method onKeyUp
     * @memberof FinanceSearch
     * @param {Object} e event object
     * @private
     */
    onKeyUp() {
        this.input.removeEventListener('keyup', this.onKeyUp);
        markOnce(MARK_FIRST_SEARCH_KEYUP);
    }
    /**
     * Adds callback for the on-cancel event handler
     * @memberof FinanceSearch
     * @param {Function} callback callback function
     * @public
     */
    addOnCancelCb(callback) {
        this.onCancelCb = callback;
    }
    /**
     * Adds a callback for the on-focus event handler
     * @memberof FinanceSearch
     * @param {Function} callback callback function
     * @public
     */
    addOnFocusCb(callback) {
        this.onFocusCb = callback;
    }
    /**
     * Adds a callback for the on-select event handler
     * @memberof FinanceSearch
     * @param {Function} callback callback function
     * @public
     */
    addOnSelectCb(callback) {
        this.onSelectCb = callback;
    }
    /**
     * Method to reset the whole instance
     * @memberof FinanceSearch
     * @method reset
     * @public
     */
    reset() {
        // cleans up all the event listeners
        this.showResults = false;
        if (this.input) {
            // remove listeners for the input
            this.input.removeEventListener('input', this.onInput);
            this.input.removeEventListener('focus', this.onFocus);
            this.input.removeEventListener('keydown', this.onKeyDown);
        }
        if (this.cancelBtn) {
            this.cancelBtn.removeEventListener('click', this.onCancel);
        }
        if (this.formNode) {
            this.formNode.removeEventListener('submit', this.onSubmit);
        }
        if (this.suggestionList) {
            this.suggestionList.reset();
        }
    }
}

export default FinanceSearch;
