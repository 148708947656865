import {
    SCTN_TYPE_QUOTES,
    SCTN_TYPE_RECOMMEND,
    SRCH_TYPES,
    getFormattedMessage,
    reverseHighlight
} from './utils';
import cssModules from '../styles/modules.css';
import { SuggestionItem } from './SuggestionItem';
import PlusIcon from '@vzmi/svg-icons/icons/fuji2/plus';
import Icon from './Icon';
/**
 * class to render the quote suggestion item
 * @class QuoteSuggestionItem
 * @extends SuggestionItem
 */
class QuoteSuggestionItem extends SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {String} [options.query] query for quote suggestion
     * @param {Number} options.index item index for this item
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     */
    constructor(options) {
        super(options);
        // when query is empty, it's recommendation item which uses the same layout as quote search
        this.type = this.query ? SCTN_TYPE_QUOTES : SCTN_TYPE_RECOMMEND;
        this.testId = this.query ? 'srch-sym' : 'srch-trd-sym';
        // use longName or shortName when query is empty for recommendations
        this.title = this.query
            ? this.suggestion.isYahooFinance
                ? this.suggestion.longname || this.suggestion.shortname || ''
                : this.suggestion.name || ''
            : this.suggestion.longName || this.suggestion.shortName || '';
    }

    /**
     * Helper function to render the quote suggestion item
     * @method render
     * @memberof QuoteSuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        // use symbol when the query is empty or isYahooFinance is true
        const symbol =
            !this.query || this.suggestion.isYahooFinance
                ? this.suggestion.symbol
                : getFormattedMessage('PRIVATE');

        const linkItem = super.render();

        const leftColumn = document.createElement('div');
        const rightColumn = document.createElement('div');
        leftColumn.className = cssModules.quoteLeftCol;
        rightColumn.className = cssModules.quoteRightCol;
        if (this.suggestion.isYahooFinance) {
            // public quote
            const quoteType = document.createElement('span');
            quoteType.textContent = `${this.suggestion.typeDisp} - ${this.suggestion.exchange}`;
            rightColumn.appendChild(quoteType);
        }
        const symbolDiv = document.createElement('div');
        symbolDiv.className = cssModules.quoteSymbol;
        if (this.searchType === SRCH_TYPES.QUOTE) {
            symbolDiv.classList.remove(cssModules.IbBox);
            const plusButton = document.createElement('span');
            plusButton.className = cssModules.quotePlusButton;
            plusButton.appendChild(
                new Icon({
                    icon: PlusIcon,
                    viewBox: '0 0 24 24',
                    width: 12,
                    height: 12,
                    className: cssModules.plusIcon
                }).render()
            );
            rightColumn.appendChild(plusButton);
        }
        symbolDiv.textContent = symbol;
        leftColumn.appendChild(symbolDiv);

        const companyNameDiv = document.createElement('div');
        companyNameDiv.className = cssModules.quoteCompanyName;
        companyNameDiv.innerHTML = reverseHighlight(this.query, this.title);
        leftColumn.appendChild(companyNameDiv);
        linkItem.appendChild(leftColumn);
        linkItem.appendChild(rightColumn);
        return linkItem;
    }
}

export default QuoteSuggestionItem;
