import escapeRegExp from 'lodash.escaperegexp';
import strings from '__I8N__';
import { selectUnit } from '@formatjs/intl-utils';
// [FDW-3814] import Intl.RelativeTimeFormat polyfill to fix relative time formatting
// for Safari / IE / pre-Chromium Edge.
// Note this does increase JS size (10K gzipped for en) for Chrome/FF/Edge for now.
// We need to optimize this later.
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'; // This gets replaced with correct lang

// Timeout for the network request
const TIMEOUT = 1500;
// Timeout for trending tickers
const TRENDING_TIMEOUT = 2000;
// Api Hosts for the newtwork request
const API_HOSTS = ['https://query1.finance.yahoo.com', 'https://query2.finance.yahoo.com'];
// Api paths
const API_PATHS = {
    SEARCH: '/v1/finance/search', // search api path
    TRENDING: '/v1/finance/trending' // trending tickers api path
};
// Traffic Search Query for instrumentation
const TRAFFIC_SRC_QUERY_STR = 'fin-srch';
// Beacon string for sending in health instrumentation
export const BEACON_STR = 'finSearchAutocomplete';
// ticker count for the ticker recommendations
const RECOMMENDED_TICKER_COUNT = 5;
// Debounce Timeout
export const DEBOUNCE_TIMEOUT = 150;
// Nav section identifier in suggestion object
export const SCTN_TYPE_NAV = 'nav';
// Quote section identifier in suggestion object
export const SCTN_TYPE_QUOTES = 'quotes';
// List section identifier in suggestion object
export const SCTN_TYPE_LIST = 'lists';
// News section identifier in suggestion object
export const SCTN_TYPE_NEWS = 'news';
// Recommended type for the suggestion item
export const SCTN_TYPE_RECOMMEND = 'recommend';
// Recommended type for the research reports item
export const SCTN_TYPE_RESEARCH_REPORTS = 'researchReports';
// This global variable would hold some context information, its set during build process
export const FIN_SRCH_CONTEXT = 'finSearchContext';
// Default Rapid `sec` value for instrumentation
export const RAPID_SEC = 'uh';
// Default Rapid `subsec` value for instrumentation
export const RAPID_SUBSEC = 'fin-search';
// Types of searches
export const SRCH_TYPES = {
    ALL: 'all',
    RESEARCH_REPORTS: 'researchReports',
    QUOTE: 'quote'
};
// config mapping for a give section identifier
export const SRCH_TYPE_CONFIG = {
    [SCTN_TYPE_QUOTES]: {
        title: 'SYMBOLS'
    },
    [SCTN_TYPE_NEWS]: {
        title: 'NEWS'
    },
    [SCTN_TYPE_LIST]: {
        title: 'LISTS'
    },
    [SCTN_TYPE_RESEARCH_REPORTS]: {
        title: 'RELATED_RESEARCH'
    },
    [SCTN_TYPE_RECOMMEND]: {
        title: 'TRENDING'
    }
};
// search section configuration
export const SRCH_SECTION_CONF = {
    [SRCH_TYPES.ALL]: [SCTN_TYPE_NAV, SCTN_TYPE_QUOTES, SCTN_TYPE_NEWS, SCTN_TYPE_LIST],
    [SRCH_TYPES.RESEARCH_REPORTS]: [
        SCTN_TYPE_NAV,
        SCTN_TYPE_QUOTES,
        SCTN_TYPE_RESEARCH_REPORTS,
        SCTN_TYPE_NEWS,
        SCTN_TYPE_LIST
    ],
    [SRCH_TYPES.QUOTE]: [SCTN_TYPE_QUOTES]
};

export const SRCH_URL_CONF = {
    [SRCH_TYPES.ALL]: {
        quotesCount: 6,
        newsCount: 4,
        enableFuzzyQuery: false,
        quotesQueryId: 'tss_match_phrase_query',
        multiQuoteQueryId: 'multi_quote_single_token_query',
        newsQueryId: 'news_cie_vespa',
        enableCb: true,
        enableNavLinks: true,
        enableEnhancedTrivialQuery: true
    },
    [SRCH_TYPES.RESEARCH_REPORTS]: {
        quotesCount: 6,
        newsCount: 3,
        listsCount: 2,
        enableFuzzyQuery: false,
        quotesQueryId: 'tss_match_phrase_query',
        multiQuoteQueryId: 'multi_quote_single_token_query',
        newsQueryId: 'news_cie_vespa',
        enableCb: true,
        enableNavLinks: true,
        enableEnhancedTrivialQuery: true,
        enableResearchReports: true
    },
    [SRCH_TYPES.QUOTE]: {
        quotesCount: 8,
        newsCount: 0,
        enableFuzzyQuery: false,
        quotesQueryId: 'tss_match_phrase_query',
        multiQuoteQueryId: 'multi_quote_single_token_query',
        enableCb: false,
        enableNavLinks: false,
        enableEnhancedTrivialQuery: true
    }
};

/**
 * Simple helper function to random API host name
 * @method getHostName
 * @returns {String} host name for the api request
 */
function getHostName() {
    const hostIndex = Math.floor(Math.random() * API_HOSTS.length);
    return API_HOSTS[hostIndex];
}

/**
 * Simple helper function to get the search url for making request
 * @method getSearchUrl
 * @param {Object} options options
 * @param {String} [options.type=all] type of search
 * @param {String} options.query type of search
 * @param {Number} [options.quotesCount=6] count for quotes results
 * @param {Object} [options.config] search config
 * @return {String} final search url
 */
function getSearchUrl({ type = SRCH_TYPES.ALL, query, config }) {
    const context = window[FIN_SRCH_CONTEXT] || {};

    let queryConfigObject = {
        q: query,
        lang: context.lang || 'en-US',
        region: context.region || 'US'
    };

    switch (type) {
        default:
        case SRCH_TYPES.ALL:
            queryConfigObject = Object.assign(
                {},
                queryConfigObject,
                SRCH_URL_CONF[SRCH_TYPES.ALL],
                config
            );
            break;
        case SRCH_TYPES.QUOTE:
            queryConfigObject = Object.assign(
                {},
                queryConfigObject,
                SRCH_URL_CONF[SRCH_TYPES.QUOTE],
                config
            );
            break;
        case SRCH_TYPES.RESEARCH_REPORTS:
            queryConfigObject = Object.assign(
                {},
                queryConfigObject,
                SRCH_URL_CONF[SRCH_TYPES.RESEARCH_REPORTS],
                config
            );
            break;
    }

    const queryStrArr = [];
    for (const key in queryConfigObject) {
        if (Object.prototype.hasOwnProperty.call(queryConfigObject, key)) {
            queryStrArr.push(`${key}=${queryConfigObject[key]}`);
        }
    }

    return `${getHostName() + API_PATHS.SEARCH}?${queryStrArr.join('&')}`;
}

/**
 * Helper function to send in a rapid click beacon
 * @method beaconClick
 * @param {String} sec the section of the module
 * @param {String} slk The text of the element or link that the click happend on
 * @param {Object} model An Object of key and value pairs that will be logged as click parameters with the click event
 * @param {Function} [callback] callback after beacon click is done.
 */
export function beaconClick(sec, slk, model, callback) {
    if (typeof window?.rapidInstance?.beaconClick === 'function') {
        // send a click event
        const pageParams = Object.assign(
            {},
            {
                etrg: 'click'
            },
            model.pp
        );
        delete model.pp;
        window.rapidInstance.beaconClick(
            sec,
            slk || '-',
            model._p || 0,
            model,
            model.outcm || 'clicked',
            callback,
            { pp: pageParams }
        );
    } else {
        typeof callback === 'function' && callback();
    }
}

/**
 * Helper function to send a rapid link view beacon
 * @method beaconLinkViews
 * @param {Object} options options for beacon
 * @param {HTMLElement} options.container parent node containing children links
 * @param {String} options.selector css query selector to find link elements
 * @param {String} options.sec the rapid section label
 * @param {String} options.subsec the rapid subsection label
 * @param {Function} [options.callback] callback after beacon click is done
 */
export function beaconLinkViews({ container, selector, sec, subsec, callback }) {
    if (
        container &&
        selector &&
        typeof window?.rapidInstance?.beaconLinkViews === 'function'
    ) {
        const linkEls = container.querySelectorAll(selector);
        if (linkEls.length > 0) {
            const linkObjects = Array.prototype.map.call(linkEls, (linkEl, i) => ({
                cpos: i,
                itc: 0,
                slk: linkEl.textContent || '',
                subsec
            }));
            const linkData = [
                {
                    _links: linkObjects,
                    sec
                }
            ];
            window.rapidInstance.beaconLinkViews(linkData, '', {}, callback);
        }
    }
}

/**
 * Helper function to send in a custom rapid event beacon
 * @param {String} event The custom event name
 * @param {Object} model An Object of key and value pairs that will be logged as rapid parameters
 */
export function beaconEvent(event, model) {
    if (typeof window?.rapidInstance?.beaconEvent === 'function') {
        // send a custom rapid event
        window.rapidInstance.beaconEvent(event, model, model.outcm || 'event');
    }
}

/**
 * Helper function to send beacons
 * @param {String} type type of beacon
 * @param {String} beaconStr beacon string/title
 * @param {Object} [body] additional params for the beacon
 */
export function sendBeacon(type, beaconStr, body = {}) {
    try {
        // temporarily piggy back on the beacon functionality from the app for now
        // since we need the beacon to monitor search autocomplete failures after GA.
        // TODO: move it to implementation independent from the app.
        if (typeof window?.context?.getActionContext === 'function') {
            const actionContext = window?.context?.getActionContext();
            if (typeof actionContext?.beacon === 'function') {
                actionContext.beacon(type, beaconStr, body);
            }
        }
    } catch (err) {
        // catch exception. we dont want any code error in this function to break application
    }
}

/**
 * A decode function as an alternative to `he.decode`. Can only be used on the browser.
 * @method decode
 * @param {String} string string to decode.
 * @returns {String} decode string content
 */
export function decode(string) {
    let decodeContainer = document.getElementById('#decode-hddn');
    if (!decodeContainer) {
        // create the container and put on the page.
        decodeContainer = document.createElement('div');
        decodeContainer.id = '#decode-hddn';
        decodeContainer.style.display = 'none';
        decodeContainer.setAttribute('aria-hidden', true);
        document.body.appendChild(decodeContainer);
    }

    decodeContainer.innerHTML = escape(string)
        .replace(/%26/g, '&')
        .replace(/%23/g, '#')
        .replace(/%3B/g, ';');
    return unescape(decodeContainer.textContent);
}

/**
 * Returns base64 encoded hash for given string
 * @method getBase64Encoding
 * @param {String} string the string to encode
 * @returns {String} base64 hash string
 */
export function getBase64Encoding(string) {
    if (!string) {
        return string;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (_, p1) =>
            String.fromCharCode(`0x${p1}`)
        )
    );
}

/**
 * Returns relative time string for a given timestamp
 * @method getRelativeTimeString
 * @param {Number} timeStamp time stamp in ms
 * @returns {String} relative time string
 */
export function getRelativeTimeString(timeStamp) {
    const timeUnit = selectUnit(timeStamp);
    const intl = (window[FIN_SRCH_CONTEXT] || {}).intl || 'en';
    if (window?.Intl?.RelativeTimeFormat) {
        // Intl would be polyfilled on the app, so it should be present everywhere in practice
        try {
            return new window.Intl.RelativeTimeFormat(intl, { numeric: 'auto' }).format(
                timeUnit.value,
                timeUnit.unit
            );
        } catch (ex) {
            // @formatjs/intl-relativetimeformat/polyfill causing TypeError in IE11,
            // which stops search result from showing.  Return empty string on error condition for now.
            // we dont want to show unix timestamp to user.
            return '';
        }
    }
    return ''; // return empty string, because we dont want to show unix timestamp to user
}

/**
 * Get new index information after a new operation is performed
 * @method getNewUpdatedIndex
 * @param {String} action action to update the selected index. Can be add,sub,reset
 * @param {Object} options additional options
 * @param {Number} options.currentIndex current index
 * @param {Object} [options.suggestions] suggestions data
 * @param {Object} [options.recommendations] recommendations data
 * @param {Boolean} [options.isRecommendation] is it recommendation or not
 * @param {Number} [options.totalItems] total number of items in the list
 * @param {String} [options.type=all] type of search
 * @returns {{ index: Number, suggestion: Object }} updated index object
 */
export function getNewUpdatedIndexObj(
    action,
    {
        currentIndex,
        suggestions,
        recommendations,
        isRecommendation = false,
        totalItems,
        type = SRCH_TYPES.ALL
    }
) {
    let newIndex;
    const searchSections = SRCH_SECTION_CONF[type];
    switch (action) {
        case 'add':
            newIndex = currentIndex < totalItems - 1 ? currentIndex + 1 : 0;
            break;
        case 'sub':
            newIndex = currentIndex > 0 ? currentIndex - 1 : totalItems - 1;
            break;
        default:
            {
                // reset to first Quote result if we have more sections, otherwise reset to 0
                // Note: we could have have this via DOM node as well, but this is going to much much faster
                newIndex = 0;
                if (!isRecommendation) {
                    // If its not recommendation, then reset the index based on the following logic:
                    // 1. If its multiquote, select that as the new index
                    // 2. If there is a quote result, first quote is your new index.
                    if (searchSections.length > 0) {
                        // Find first quote index
                        let found = false;
                        for (
                            let sectionIndex = 0;
                            sectionIndex < searchSections.length;
                            sectionIndex++
                        ) {
                            const section = searchSections[sectionIndex];
                            const secSections = suggestions[section];

                            if (section === SCTN_TYPE_NAV) {
                                // if its nav, check if there is multiquote
                                const [newSuggestion] = secSections;
                                if (newSuggestion?.navType === 'MULTIQUOTE') {
                                    // its a multiquote, select it.
                                    found = true;
                                    break;
                                }
                            }

                            if (section === SCTN_TYPE_QUOTES && secSections.length > 0) {
                                // we found quotes section.
                                found = true;
                                break;
                            }

                            // keep adding to new index
                            newIndex += secSections?.length ?? 0;
                        }
                        // if we by any case we didn't found any quote section in suggestions, reset it back to 0
                        newIndex = found ? newIndex : 0;
                    }
                }
            }
            break;
    }

    let selectedSuggestion = null;
    if (action === 'add' || action === 'sub') {
        if (!isRecommendation) {
            // Get the selected suggestion
            let itemIndex = 0;
            for (let sectionIndex = 0; sectionIndex < searchSections.length; sectionIndex++) {
                const section = searchSections[sectionIndex];
                const secSuggestions = suggestions[section];
                const sectionLength = secSuggestions?.length;
                if (itemIndex + sectionLength > newIndex) {
                    // ok we found the section where we want to be.
                    selectedSuggestion =
                        secSuggestions && secSuggestions[newIndex - itemIndex];
                    break;
                }

                itemIndex += sectionLength;
            }
        } else {
            // Its a recommendation
            selectedSuggestion = recommendations && recommendations[newIndex];
        }
    }

    return { index: newIndex, suggestion: selectedSuggestion };
}

/**
 * Helper function to get closest ancestor given a string
 * @method getClosestAncestor
 * @param {Node} node dom node to get the ancestor for
 * @param {String} selector node selector string
 * @param {Node} containerNode acts as a stop boundary for search
 * @return {Node} closest ancestor node
 */
export function getClosestAncestor(node, selector, containerNode) {
    let target = node;
    if (target && selector) {
        do {
            if (target.matches(selector)) {
                // we got a match, so return
                return target;
            }
            target = target.parentNode; // switch to parent
        } while (containerNode !== target && !!target);
    }
    return null;
}

/**
 * Simple helper function to make the search request and get suggestions
 * @method getQuerySuggestions
 * @param {Object} options options
 * @param {String} [options.type=all] type of search
 * @param {String} options.query type of search
 * @param {Number} [options.quotesCount=6] count for quotes results
 * @param {Object} [options.config] search config
 * @return {Promise} promise for query suggestion
 */
export function getQuerySuggestions({ type = SRCH_TYPES.ALL, query, config }) {
    const finalUrl = getSearchUrl({ type, query, config });
    return makeRequest(finalUrl, {});
}

/**
 * Simple helper function to make trending ticker request and get ticker recommendations
 * @method getTrendingTickers
 * @param {Object} options options
 * @param {Number} [options.count=5] number of results to request
 * @param {String} [options.region=US] the region for the request
 * @param {String} options.fields additonal fields to query
 * @return {Promise} promise for trending ticker recommendations
 */
export function getTrendingTickers({
    count = RECOMMENDED_TICKER_COUNT,
    fields,
    region = 'US'
}) {
    const baseUrl = `${getHostName()}${API_PATHS.TRENDING}/${region}`;
    const params = new URLSearchParams({ count });
    if (fields) {
        // fetch addtional fields
        params.set('useQuotes', true);
        params.set('fields', fields);
    }
    const finalUrl = `${baseUrl}?${params.toString()}`;
    return makeRequest(finalUrl, {
        errorMsg: 'Trending Tickers failed',
        beaconString: 'finSearchTrending',
        timeout: TRENDING_TIMEOUT
    }).then((finalData) => {
        if (finalData.finance.error) {
            // there is some error
            throw {
                status: 500,
                message: finalData.finance.error
            };
        }

        // Get the results
        return finalData.finance.result[0].quotes;
    });
}

/**
 * Simple helper function to make a request using Promise
 * @method makeRequest
 * @param {String} url url for the request
 * @param {Object} options options
 * @param {Number} [options.timeout=1500] timeout for the request.
 * @param {String} [options.errorMsg=Search Autocomplete failed] default error message
 * @param {String} [options.beaconString=finSearchAutocomplete] string for the beacons being sent out on success or failure
 * @return {Promise} promise with the result of the request
 */
export function makeRequest(
    url,
    { timeout = TIMEOUT, errorMsg = 'Search Autocomplete failed', beaconString = BEACON_STR }
) {
    return new Promise((resolve, reject) => {
        const timestamp = Date.now();

        /**
         * callback function for successful response
         * @param {Object} finalData the successfully parsed response data
         */
        function successCb(finalData = {}) {
            finalData.timestamp = timestamp; // Add a timestamp property to the final response.
            sendBeacon('info', beaconString, { code: 200, surl: url });
            resolve(finalData);
        }

        /**
         * callback function for errorneous response
         * @param {Object} error error object
         */
        function errorCb(error = {}) {
            error.status = error.status || 500;
            error.message = error.message || errorMsg;
            sendBeacon('error', beaconString, {
                code: error.status,
                msg: error.message,
                surl: url
            });
            reject(error);
        }

        if (typeof window.fetch !== 'undefined') {
            // Make fetch request
            makeFetchRequest(url, { timeout, successCb, errorCb });
        } else {
            // Otherwise Make XHR request
            makeXHRRequest(url, { timeout, successCb, errorCb });
        }
    });
}

/**
 * Simple helper function to make a request using XMLHttpRequest
 * @method makeXHRRequest
 * @param {String} url url for the request
 * @param {Object} options options
 * @param {Number} [options.timeout=1500] timeout for the request.
 * @param {Function} options.successCb callback function for Ok request
 * @param {Function} options.errorCb callback function for Error request
 */
export function makeXHRRequest(url, { timeout = TIMEOUT, successCb, errorCb }) {
    if (typeof window.XMLHttpRequest !== 'undefined' && window.XMLHttpRequest) {
        const xhr = new window.XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.timeout = timeout; // timeout for the request
        xhr.withCredentials = true;
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                // ok request (https://developer.mozilla.org/en-US/docs/Web/HTTP/Status)
                let finalData = xhr.responseText;
                if (typeof xhr.responseText === 'string') {
                    // if response is string, then parse it
                    try {
                        finalData = JSON.parse(xhr.responseText);
                    } catch (ex) {
                        // exception while parsing
                        errorCb({
                            status: 502,
                            message: ex.message
                        });
                        return;
                    }
                }
                successCb(finalData);
                return;
            }
            // its an error
            errorCb({
                status: xhr.status || 502,
                message: xhr.responseText
            });
        };
        xhr.onerror = () => {
            errorCb({
                status: xhr.status || 500,
                message: xhr.responseText
            });
        };

        // send the request
        xhr.send();
    } else {
        // no XMLHttpRequest, thats ancient
        errorCb({
            status: 500,
            message: 'XMLHttpRequest not supported'
        });
    }
}

/**
 * Simple helper function to make a request using browser Fetch Api
 * @method makeFetchRequest
 * @param {String} url url for the request
 * @param {Object} options options
 * @param {Number} [options.timeout=1500] timeout for the request.
 * @param {Function} options.successCb callback function for Ok request
 * @param {Function} options.errorCb callback function for Error request
 */
export function makeFetchRequest(url, { timeout = TIMEOUT, successCb, errorCb }) {
    let requestTimedOut = false;

    // Handle timeout
    const timeoutHandle = setTimeout(function () {
        requestTimedOut = true;
        // clear timeout
        clearTimeout(timeoutHandle);
        errorCb({
            status: 502,
            message: 'Autocomplete timeout'
        });
    }, timeout);

    // Make the fetch request
    window
        .fetch(url, {
            mode: 'cors',
            credentials: 'include'
        })
        .then((res) => {
            if (requestTimedOut) {
                // if request timed out, return
                return;
            }

            // clear timeout for next request
            clearTimeout(timeoutHandle);

            if (!res.ok) {
                // its an error response
                return Promise.reject({
                    status: res.status || 502,
                    message: res.statusText
                });
            }

            return res.json();
        })
        .then((finalData) => {
            // Ok request
            successCb(finalData);
        })
        .catch((ex) => {
            // Some error happened
            // istanbul ignore next
            if (requestTimedOut) {
                // if request timed out, return as we already sent the timeout error
                return;
            }
            errorCb({
                status: ex.status || 502,
                message: ex.message
            });
        });
}

/**
 * Method to debounce a given function (uses trailing edge)
 * We are not using lodash because 1. We don't need all the functionality, and 2. It pulls in lots of deps
 * @method debounce
 * @param {function} func function to debounce
 * @param {number} wait ms to wait
 * @return {Function} debounced function
 */
export function debouncePromise(func, wait) {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);
        return new Promise((resolve) => {
            timeout = setTimeout(() => resolve(func(...args)), wait);
        });
    };
}

/**
 * Helper function to generate the highlighted markup
 * @method reverseHighlight
 * @param {String} query the query input by the user
 * @param {String} value the string in which query is to be highlighted
 * @returns {Object} highlighted markup in an object
 */
export function reverseHighlight(query, value) {
    let finalHtml = '';
    if (query && value) {
        // we need to escape the query because query might contain regex characters which would screw up our logic.
        const regex = new RegExp(escapeRegExp(query), 'ig');
        let match;
        let lastMatch = -1;

        /* eslint-disable-next-line no-cond-assign */
        while ((match = regex.exec(value))) {
            finalHtml += value.substring(
                lastMatch === -1 ? 0 : lastMatch + query.length,
                match.index
            );
            finalHtml += `<strong>${value.substr(match.index, query.length)}</strong>`;
            lastMatch = match.index;
        }
        if (lastMatch !== -1) {
            // if there was some match
            finalHtml += value.substr(lastMatch + query.length);
        }
    }

    // if no match, then render the whole value
    return finalHtml || value;
}

/**
 * Helper function to get the suggestion nav item information
 * @method getSuggestionNavItemInfo
 * @param {Object} suggestion suggestion object
 * @returns {Object} object payload having new href and text
 */
export function getSuggestionNavItemInfo(suggestion) {
    let href = '';
    let text = '';
    switch (suggestion.navType || suggestion.navName) {
        // navType only:
        case 'MULTIQUOTE':
            text = suggestion.symbols.join(',');
            href = `/quotes/${text}`;
            break;
        case 'EARNINGS_CALENDAR':
            text = 'Earnings Calendar';
            href = '/calendar/earnings';
            break;
        case 'STOCK_SPLITS_CALENDAR':
            text = 'Stock Splits Calendar';
            href = '/calendar/splits';
            break;
        case 'IPO_CALENDAR':
            text = 'IPO Events';
            href = '/calendar/ipo';
            break;
        case 'ECONOMIC_EVENTS':
            text = 'Economic Events';
            href = '/calendar/economic';
            break;
        // navName only:
        case 'Mortgage Rates':
            text = 'Rates';
            href = suggestion.navUrl;
            break;
        case 'yf':
        case 'Yahoo Finance':
            text = 'Finance Home';
            href = suggestion.navUrl;
            break;
        case 'Help':
            text = 'Help for Yahoo Finance';
            href = suggestion.navUrl;
            break;
        case 'Calendars':
        case 'Markets':
            text = 'Events Calendar';
            href = suggestion.navUrl;
            break;
        // make backwards compatible (can delete when new response is in prod)
        case 'CURRENCIES':
            text = 'Currencies';
            href = '/currencies';
            break;
        case 'CALENDAR':
            text = 'Events Calendar';
            href = '/calendar';
            break;
        case 'CURRENCY_CONVERTOR':
            text = 'Currency Converter';
            href = '/currency-converter';
            break;
        case 'RATES':
            text = 'Rates';
            href = '/rates';
            break;
        case 'YF_HOMEPAGE':
            text = 'Homepage';
            href = '/';
            break;
        case 'YF_HELP_PAGE':
            text = 'Help for Yahoo Finance';
            href = 'https://help.yahoo.com/kb/finance-for-web';
            break;
        case 'MY_PORTFOLIO':
            text = 'My Portfolio';
            href = '/portfolios';
            break;
        case 'ALL_MARKETS_SUMMIT':
            text = 'Yahoo Finance All Markets Summit';
            href = '/live/allmarketssummit';
            break;
        default:
            text = suggestion.navName;
            href = suggestion.navUrl;
            break;
    }

    return {
        href,
        text
    };
}

/**
 * Helper function to generate the URL
 * @param {String} type type of url to generate
 * @param {Object} [params] additional params
 * @param {String} [params.symbol] quote symbol for certain types
 * @param {String} [params.id] id for certain types
 * @param {String} [params.hash] hash string if any (used for company)
 * @param {String} [params.link] provided link which can be used as final url, (for link type)
 * @param {String} [params.slug] a slug provided for certain types
 * @param {String} [params.brandSlug] a brand slug provided for certain types
 * @param {Boolean} [params.useAbsolute=false] used for type=link, if provided then use absolute url, otherwise always use relative
 * @returns {String} final url to use
 */
export function generateURL(type, params = {}) {
    let url = '/';
    switch (type) {
        case 'screener':
            if (params.id) {
                // we have a screener id
                url = `/screener/predefined/${params.id}?.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            } else {
                // no screener id
                url = `/screener?.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            }
            break;
        case 'algowatchlist':
            url = `/u/${params.brandSlug}/watchlists/${params.slug}?.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            break;
        case 'quote':
            url = `/quote/${params.symbol}?p=${params.symbol}&.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            break;
        case 'company':
            url = `/company/${params.symbol}?h=${params.hash}&.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            break;
        case 'link':
            if (!params.link) {
                url = '#';
            } else {
                if (params.link[0] !== '/' && !params.useAbsolute) {
                    // Use relative url for this absolute url
                    url = `${new URL(params.link).pathname}?.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
                } else {
                    // Use the same url
                    url = `${params.link}?.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
                }
            }
            break;
        case 'researchReports':
            url = '/research';
            if (params.reportId) {
                url += `/reports/${params.reportId}`;
            }
            url += `?.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            break;
        case 'lookup':
            url = `/lookup?s=${params.query}&.tsrc=${TRAFFIC_SRC_QUERY_STR}`;
            break;
    }
    return url;
}

/**
 * Helper function to get intl formatted message
 * @method getFormattedMessage
 * @param {String} id string id
 * @param {Object} [values] values object for the formatted message
 * @return {String} intl message
 */
export function getFormattedMessage(id, values) {
    let str = strings[id];
    if (!str) {
        return id;
    }

    if (values) {
        Object.keys(values).forEach((value) => {
            str = str.replace(new RegExp(`{${value}}`, 'g'), values[value]);
        });
    }

    return str;
}
