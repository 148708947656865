import DownloadSpreadsheetIcon from '@vzmi/svg-icons/icons/download-spreadsheet';
import Icon from './Icon';
import { SCTN_TYPE_RESEARCH_REPORTS, decode, getRelativeTimeString } from './utils';
import cssModules from '../styles/modules.css';
import { SuggestionItem } from './SuggestionItem';

/**
 * class to render the research reports suggestion item
 * @class ResearchReportsSuggestionItem
 * @extends SuggestionItem
 */
class ResearchReportsSuggestionItem extends SuggestionItem {
    /**
     * @constructor
     * @param {Object} options options for the constructor
     * @param {Object} options.suggestion suggestion item
     * @param {Number} options.index item index for this item
     * @param {Number} options.positionIndex index of this item in the overall list across all sections
     * @param {String} [options.className] additional class for this node
     */
    constructor(options) {
        super(options);
        this.type = SCTN_TYPE_RESEARCH_REPORTS;
        this.testId = 'srch-rsrch-rpts';
        this.title = decode(this.suggestion.reportHeadline || '');
    }

    /**
     * Helper function to render the research reports suggestion item
     * @method render
     * @memberof ResearchReportsSuggestionItem
     * @returns {Node} the item dom node
     */
    render() {
        const linkItem = super.render();

        const leftColumn = document.createElement('div');
        const rightColumn = document.createElement('div');
        leftColumn.className = cssModules.itemLeftCol;
        rightColumn.className = cssModules.itemRightCol;

        const iconDiv = document.createElement('div');
        iconDiv.className = cssModules.itemIconContainer;
        iconDiv.appendChild(
            new Icon({
                icon: DownloadSpreadsheetIcon,
                className: cssModules.researchIcon,
                viewBox: '0 0 48 48'
            }).render()
        );
        leftColumn.appendChild(iconDiv);

        const researchTitleDiv = document.createElement('h5');
        researchTitleDiv.className = cssModules.newsTitle;
        researchTitleDiv.textContent = this.title;

        const researchDiv = document.createElement('div');
        researchDiv.className = cssModules.newsMeta;

        const author = document.createElement('span');
        author.textContent = decode(this.suggestion.provider || this.suggestion.author || '');

        researchDiv.appendChild(author);
        const seperator = document.createElement('i');
        seperator.setAttribute('aria-hidden', 'true');
        seperator.className = cssModules.researchSeperator;
        seperator.innerHTML = '•';
        researchDiv.appendChild(seperator);

        const publishTime = document.createElement('span');
        publishTime.textContent = getRelativeTimeString(this.suggestion.reportDate);
        researchDiv.appendChild(publishTime);

        rightColumn.appendChild(researchTitleDiv);
        rightColumn.appendChild(researchDiv);

        linkItem.appendChild(leftColumn);
        linkItem.appendChild(rightColumn);
        return linkItem;
    }
}

export default ResearchReportsSuggestionItem;
